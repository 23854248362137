import { InputField, InputFieldProps } from '@admiral-ds/react-ui';

import { UseFieldResult } from '../useField';

import { Error } from '#Entry/components/Error';

export type TextFieldProps = Omit<InputFieldProps, 'onChange'> &
  Pick<UseFieldResult<string>, 'value' | 'onChange' | 'onBlur' | 'errorText'> & {
    errorHeight?: number;
  };

export function TextField(props: TextFieldProps) {
  const { errorText, errorHeight = 0, onChange, ...inputFieldProps } = props;
  const minHeight = errorHeight + 40 + (props.label ? 24 : 0);

  return (
    <div>
      <InputField
        {...inputFieldProps}
        onChange={(e) => onChange(e.target.value)}
        extraText={<Error errorText={errorText} />}
      />
    </div>
  );
}
