import { Box } from '@welcome-ui/box';
import { Button, Modal, ModalTitle, ModalContent, ModalButtonPanel } from '@admiral-ds/react-ui';

import { getDocsStorageBaseUrl } from '#utils/getApiUrl';
import { useDisplayedLinks } from '#user/useDisplayedLinks';

export const EmploymentDetailsModal = ({ onCloseModal }: { onCloseModal: (reason?: unknown) => void }) => {
  const { getByCode } = useDisplayedLinks({ fetchStrategy: 'no-auto-fetch' });
  return (
    <Modal onClose={onCloseModal} style={{ maxWidth: 'calc(100% - 48px)' }}>
      <ModalTitle id="modal-title">Как проходит процесс оформления?</ModalTitle>
      <ModalContent style={{ background: '#DDE9FF' }}>
        <Box>
          1. Согласуйте дату и время визита с рекрутером
          <br />
          2. Возьмите с собой оригиналы документов (
          <a href={`${getDocsStorageBaseUrl()}/${getByCode('DOCS_LIST')?.path}`} target="_blank">
            {getByCode('DOCS_LIST')?.title ?? 'список документов'}
          </a>
          )
          <br />
          3. Приезжайте в офис к назначенному времени
          <br />
          4. В офисе подпишите комплект документов о приеме на работу на бумаге
          <br />
        </Box>
      </ModalContent>
      <ModalButtonPanel>
        <Button appearance="primary" dimension="m" onClick={onCloseModal}>
          Понятно
        </Button>
      </ModalButtonPanel>
    </Modal>
  );
};
