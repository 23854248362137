import React from 'react';
import { useOffer } from '#user/useOffer';
import { OfferProcessingPaper } from './components/OfferProcessingPaper';
import { OfferProcessingDigital } from './components/OfferProcessingDigital';

export const OfferAccepted = React.memo(() => {
  const { offer, isReady } = useOffer({ autoFetch: true });

  if (!isReady) return null;

  switch (true) {
    case offer?.decision?.data?.processingType === 'PAPER':
      return <OfferProcessingPaper offer={offer!} />;
    case offer?.decision?.data?.processingType === 'DIGITAL':
      return <OfferProcessingDigital offer={offer!} />;
    default:
      return <div>unknown offer processing type {offer?.decision?.data?.processingType}</div>;
  }
});
