import React from 'react';
import { useStatus } from '#user/useStatus';
import { Redirect } from 'react-router-dom';

export interface StatusGateProps {
  children: React.ReactNode;
  employmentStatuses?: string[];
  registered?: boolean;
  mustVerifyEmailCheck?: boolean;
  redirectTo?: string;
  fallback?: React.ReactNode | null;
  error?: React.ReactNode | null;
  debug?: boolean | string;
}

const REDIRECTS_PER_STATUS: Record<string, { redirectTo: string }> = {
  CONSENT_ACCEPTED: {
    redirectTo: '/consent-accepted',
  },
  DOCUMENTS_UPLOADING: {
    redirectTo: '/documents',
  },
  DOCUMENTS_SENT: {
    redirectTo: '/documents-sent',
  },
  OFFER_RECEIVED: {
    redirectTo: '/offer-received',
  },
  OFFER_ACCEPTED: {
    redirectTo: '/offer-accepted',
  },
  OFFER_ACCEPTING: {
    redirectTo: '/offer-accepting',
  },
  OFFER_REJECTED: {
    redirectTo: '/offer-rejected',
  },
};

export const StatusGate = React.memo((props: StatusGateProps) => {
  const {
    employmentStatuses,
    registered,
    children,
    fallback = null,
    error = <Redirect to="/login" />,
    mustVerifyEmailCheck = true,
    debug = false,
  } = props;
  const status = useStatus();
  const { isReady, employmentStatus, profileStatus, isError } = status;
  // const history = useHistory();

  if (debug) {
    console.groupCollapsed(`render StatusGate ${typeof debug === 'string' ? debug : ''}`);
    console.log('props', props);
    console.log('status', status);
    console.groupEnd();
  }

  const isUserNotRegistered = profileStatus === 'NOT_REGISTERED';
  const isUserMustVerifyEmail = profileStatus === 'MUST_VERIFY';

  if (mustVerifyEmailCheck && isUserMustVerifyEmail) return <Redirect to="/verify-email" />;
  if (isError) return error;
  if (!profileStatus || !isReady) return fallback;

  const isForbidByEmploymentStatus =
    Array.isArray(employmentStatuses) && !employmentStatuses.includes(employmentStatus ?? '');
  const isForbidByRegistered = typeof registered === 'boolean' && isUserNotRegistered === registered;

  if (isForbidByRegistered) {
    return <Redirect to={isUserNotRegistered ? '/register' : '/profile'} />;
  }

  if (isForbidByEmploymentStatus) {
    if (debug) {
      console.log('isForbidByEmploymentStatus', isForbidByEmploymentStatus);
    }
    const { redirectTo } = REDIRECTS_PER_STATUS[employmentStatus ?? ''] ?? {
      redirectTo: isUserNotRegistered ? '/register' : '/profile',
    };
    if (debug) {
      console.log('isForbidByEmploymentStatus', isForbidByEmploymentStatus);
      console.log('redirectTo', redirectTo);
    }
    return <Redirect to={redirectTo} />;
  }

  return children;
});
