import React from 'react';
import { Box } from '@welcome-ui/box';
import { isCookiesEnabled } from '#utils/cookies';
import { T } from '@admiral-ds/react-ui';

export const EntryPageLayout = React.memo((props: { children: React.ReactNode }) => {
  return (
    <Box display="flex" w="100vw" minH="100vh" justifyContent="center" alignItems="center">
      <Box maxWidth="1280px" maxHeight="832px">
        {props.children}
        {!isCookiesEnabled() && (
          <Box
            position="absolute"
            left="0"
            top="0"
            w="100vw"
            h="100vh"
            background="rgba(123,123,153,0.7)"
            bottom="100%"
            zIndex="1"
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Box
              borderRadius="8"
              maxW={420}
              minH={120}
              position="absolute"
              left="50%"
              top="50%"
              transform="translateX(-50%) translateY(-50%)"
              background="white"
              p={32}
              pb={48}
              textAlign="center"
            >
              <Box pb={16}>
                <T font="Header/H4">Cookie!</T>
              </Box>
              <T as="p" font="Body/Body 1 Short">
                Для корректной работы личного кабинета необходимы cookie.
              </T>
              <T as="p" font="Body/Body 1 Short">
                Пожалуйста, включите cookie в настройках браузера и перезагрузите страницу.
              </T>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
});
