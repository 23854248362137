import React from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { T } from '@admiral-ds/react-ui';
import hotAirBaloonImg from '#assets/hot-air-balloon.svg';

const Text = styled(T)`
  margin: 0;
`;

const Image = styled.img`
  display: block;
  width: 120px;
  height: 120px;
`;

export const ConsentAccepted = React.memo(() => {
  return (
    <Box w="928px" mb="40px">
      <Box mt="24px">
        <Text font="Header/H3">Документы</Text>
      </Box>
      <Box mt="154px">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box>
            <Image style={{ display: 'block' }} src={hotAirBaloonImg} />
          </Box>
          <Box maxWidth="496px" mt="24px" textAlign="center">
            <Text font="Header/H5" color="Neutral/Neutral 90">
              Рекрутер направит запрос на загрузку документов в ближайшее время.
              <br />
              Ожидайте поступления уведомления
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
