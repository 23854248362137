import { UserProfileContextStore } from './UserProfileContext';

export function makeSelectors(root: UserProfileContextStore | null) {
  return {
    getDisplayName() {
      const lastName = root?.profile?.data?.candidate?.lastName ?? '';
      const firstName = root?.profile?.data?.candidate?.firstName ?? '';

      if (!lastName && !firstName) return '';

      return `${lastName} ${firstName}`;
    },
    getEmail() {
      return root?.profile?.data?.candidate?.email ?? null;
    },
    getProfileStatus() {
      return root?.profile?.data?.status ?? null;
    },
    getEmploymentStatus() {
      return root?.profile?.data?.employment?.status ?? null;
    },
    getVacancyId() {
      return root?.profile?.data?.employment?.vacancyId ?? null;
    }
  };
}
