import React from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { T } from '@admiral-ds/react-ui';

import gosuslugi from '#assets/gosuslugi.svg';
import pc from '#assets/pc.svg';
import cert from '#assets/cert.svg';
import check from '#assets/check.svg';
import mobileApp from '#assets/mobile-app.svg';

const Text = styled(T)`
  margin: 0;
`;

const StyledWrapper = styled(Box)`
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 16px;
  }
`;

export const DigitalSignatureAgitation = React.memo(() => {
  return (
    <StyledWrapper display="flex" columnGap="16px" flexDirection="column">
      <Box>
        <Text font="Subtitle/Subtitle 2">Преимущества электронного подписания</Text>
      </Box>
      <Box pt="16px" pb="8px" display="flex">
        <Box pl="4px" position="relative">
          <img src={pc} />
          <Box position="absolute" top="20px" left="32px">
            <img src={gosuslugi} />
          </Box>
        </Box>
        <Box>
          <Box px="16px" pt="8px">
            <Text font="Subtitle/Subtitle 2">Вход через Госуслуги</Text>
          </Box>
          <Box px="16px" pt="2px">
            <Text font="Body/Body 2 Long">По подтвержденной учётной записи</Text>
          </Box>
        </Box>
      </Box>

      <Box pt="8px" pb="8px" display="flex">
        <Box pl="4px" position="relative">
          <img src={cert} />
        </Box>
        <Box>
          <Box px="16px" pt="8px">
            <Text font="Subtitle/Subtitle 2">Все документы в электронном виде</Text>
          </Box>
          <Box px="16px" pt="2px">
            <Text font="Body/Body 2 Long">С доступом в любое время в платформе Автограф</Text>
          </Box>
        </Box>
      </Box>

      <Box pt="8px" pb="8px" display="flex">
        <Box pl="4px" position="relative">
          <img src={check} />
        </Box>
        <Box>
          <Box px="16px" pt="8px">
            <Text font="Subtitle/Subtitle 2">Электронная подпись равнозначна собственноручной</Text>
          </Box>
          <Box px="16px" pt="2px">
            <Text font="Body/Body 2 Long">И одобрена законом</Text>
          </Box>
        </Box>
      </Box>

      <Box pt="8px" pb="8px" display="flex">
        <Box pl="4px" position="relative">
          <img src={mobileApp} />
        </Box>
        <Box>
          <Box px="16px" pt="8px">
            <Text font="Subtitle/Subtitle 2">Подписание документов в режиме онлайн</Text>
          </Box>
          <Box px="16px" pt="2px">
            <Text font="Body/Body 2 Long">
              С использованием{' '}
              <Text font="Body/Body 2 Long" color="Primary/Primary 60 Main">
                <a href="https://goskey.ru/" target="_blank">
                  МП Госключ
                </a>
              </Text>
            </Text>
          </Box>
        </Box>
      </Box>
    </StyledWrapper>
  );
});
