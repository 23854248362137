import { ApiCallFailure } from './apiCallState';

type ErrorSpecificDetails = {
  [fieldName: string]: string;
};

interface ErrorMoreDetails {
  id: string;
  timestamp: string;
  errorSpecific: {
    code: 'VALIDATION_ERROR';
    details: Array<ErrorSpecificDetails>;
  };
}
interface ApiDetailedError {
  detail: string;
  instance?: string;
  status: number;
  title: string;
  type: string;

  moreDetails: ErrorMoreDetails;
}

export type ApiErrorBody = ApiDetailedError;

export type ErrorFields = Record<
  string,
  {
    pushError(params: { error: string; errorDetails: unknown }): void;
  }
>;

// TODO: типы, сконектить с useField, структурировать
export function processApiError(apiError: ApiCallFailure<ApiErrorBody>['error'], fields: ErrorFields) {
  if (!apiError) return;

  const { details } = apiError?.moreDetails?.errorSpecific ?? {};

  if (details && Array.isArray(details) && details.length > 0) {
    details.forEach((detail) => {
      const keys = Object.keys(detail);
      for (const k of keys) {
        if (fields[k]) fields[k].pushError({ error: detail[k], errorDetails: {} });
      }
    });
  }
}
