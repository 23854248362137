import React from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { T } from '@admiral-ds/react-ui';
import bigHeart from '#assets/big-heart.svg';

const Text = styled(T)`
  margin: 0;
`;

const Image = styled.img`
  display: block;
  width: 120px;
  height: 120px;
`;

export const DocumentsSent = React.memo(() => {
  return (
    <Box mb="40px" px="32" w="100%">
      <Box mt="24px">
        <Text font="Header/H3">Документы</Text>
      </Box>
      <Box mt="154px">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box>
            <Image style={{ display: 'block' }} src={bigHeart} />
          </Box>
          <Box maxWidth="469px" mt="24px" textAlign="center">
            <Text font="Header/H5" color="Neutral/Neutral 90">
              Документы успешно отправлены <br />
              Ожидайте уведомления от рекрутера
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
