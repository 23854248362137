import { Box } from '@welcome-ui/box';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import documentsImg from '#assets/documents.svg';
import handshakeImg from '#assets/handshake.svg';
import personImg from '#assets/person.svg';
import { T } from '@admiral-ds/react-ui';
import { useHistory, useLocation } from 'react-router-dom';
import { isAnyOfferStatus, useStatus } from '#user/useStatus';

const NavigationWrapper = styled(Box)<{ isOpen: boolean }>`
  @media (min-width: 640px) {
    display: block;
  }
  @media (max-width: 639px) {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: ${(props) => (props.isOpen ? 'absolute' : 'none')};
  }
`;

const NavigationItem = styled.div<{ active?: boolean; disabled?: boolean }>`
  position: relative;
  display: flex;
  column-gap: 4px;

  width: 100%;
  padding: 8px 24px;
  box-sizing: border-box;

  background-color: ${(props) => (props.active ? '#DDE9FF' : 'transparent')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props) => (props.disabled || props.active ? 'none' : 'all')};
  cursor: pointer;

  & ::after {
    position: absolute;
    content: ' ';
    right: 0;
    top: 0;
    display: ${(props) => (props.active ? 'block' : 'none')};
    width: 4px;
    height: 40px;
    background: #0132b0;
  }
`;

const Text = styled(T)`
  margin: 0;
`;

export const SideNavigation = React.memo(
  React.forwardRef(
    ({ isOpen, toggleIsOpen }: { isOpen: boolean; toggleIsOpen(): void }, ref: React.ForwardedRef<HTMLDivElement>) => {
      const { pathname } = useLocation();
      const history = useHistory();
      const {
        isReady: isStatusReady,
        employmentStatus,
        profileStatus,
        documentsDisabled,
        documentsLink,
        documentsVisible,
        offerVisible,
      } = useStatus({ autoFetch: false });

      const makeOnNavigationItemKeyPress = useCallback((action: () => void) => {
        return (e: React.KeyboardEvent) => {
          switch (e.key) {
            case 'Enter':
            case 'Space':
              action();
              break;
            default:
          }
        };
      }, []);

      const onNavigateTo = useCallback(
        (to: string) => {
          toggleIsOpen();
          history.push(to);
        },
        [history, toggleIsOpen],
      );

      // TODO: loading пока нет инфы о профиле?
      // для каждого элемента по отдельности (хедер, сайдбар, документы) или на всю страницу
      if (!isStatusReady) {
        return <Box w="240px" boxSizing="border-box" p="32px 0" background="#E5F6FF"></Box>;
      }

      const isProfileDisabled = false;
      const isMustVerify = profileStatus === 'MUST_VERIFY';
      const isDocumentsDisabled = isMustVerify || documentsDisabled;
      const isOfferDisabled = profileStatus === 'MUST_VERIFY' || !isAnyOfferStatus(employmentStatus);

      return (
        <NavigationWrapper w="240px" boxSizing="border-box" p="32px 0" background="#E5F6FF" isOpen={isOpen} ref={ref}>
          <NavigationItem
            tabIndex={isProfileDisabled ? undefined : 0}
            active={['/profile', '/verify-email'].includes(pathname)}
            onClick={() => onNavigateTo('/profile')}
            onKeyDown={makeOnNavigationItemKeyPress(() => onNavigateTo('/profile'))}
            disabled={isProfileDisabled}
          >
            <img src={personImg} />
            <Text font="Body/Body 1 Long">Профиль</Text>
          </NavigationItem>
          {documentsVisible && (
            <NavigationItem
              tabIndex={isDocumentsDisabled ? undefined : 0}
              active={['/consent-accepted', '/documents', '/documents-sent'].includes(pathname)}
              onClick={() => onNavigateTo(documentsLink)}
              onKeyDown={makeOnNavigationItemKeyPress(() => onNavigateTo(documentsLink))}
              disabled={isDocumentsDisabled}
            >
              <img src={documentsImg} />
              <Text font="Body/Body 1 Long">Документы</Text>
            </NavigationItem>
          )}
          {offerVisible && (
            <NavigationItem
              tabIndex={isOfferDisabled ? undefined : 0}
              active={['/offer-received', '/offer-accepting', '/offer-rejected', '/offer-accepted'].includes(pathname)}
              onClick={() => onNavigateTo('/offer-received')}
              onKeyDown={makeOnNavigationItemKeyPress(() => onNavigateTo('/offer-received'))}
              disabled={isOfferDisabled}
            >
              <img src={handshakeImg} />
              <Text font="Body/Body 1 Long">Предложение</Text>
            </NavigationItem>
          )}
        </NavigationWrapper>
      );
    },
  ),
);
