import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { T, InputField, TextField } from '@admiral-ds/react-ui';
import { findOfferRejectionByCode, useOffer } from '#user/useOffer';
import { useApiQuery } from '#api/useApiCall';
import { OfferRejectionType, RejectionData, RejectionDecision } from '#api/dtos';
import { formatDate } from '#utils/date';
import { INVITATION_TEXT } from '../../constants';

const Text = styled(T)`
  margin: 0;
`;

const StyledWrapper = styled(Box)`
  & input[type='text'][disabled]:disabled,
  & textarea[disabled]:disabled {
    color: #23262d;
  }
`;

export const OfferRejected = React.memo(() => {
  const { offer, isReady } = useOffer();
  const [rejectionTypesState, loadRejectionTypes] = useApiQuery<OfferRejectionType[]>('/rejection-types', {});

  useEffect(() => {
    loadRejectionTypes();
  }, []);

  // todo: get from data
  const rejectionData = useMemo(() => {
    if (offer?.decision?.code !== 'rejected') return null;

    const offerRejectionData = findOfferRejectionByCode(
      (offer?.decision?.data as RejectionData).rejectionCode,
      rejectionTypesState.data,
    );

    return offerRejectionData;
  }, [offer?.decision?.code, offer?.decision?.data, rejectionTypesState.data]);

  const rejectionReasonText = rejectionData?.title ?? '';
  const rejectionCommentText = (offer?.decision as RejectionDecision)?.data.rejectionComment ?? '';

  if (!offer || !isReady) return null;

  return (
    <StyledWrapper w="928px" mb="40px">
      {/* <pre>{JSON.stringify(rejectionTypesState, null, 2)}</pre>
      <pre>{JSON.stringify(offer, null, 2)}</pre> */}
      <Box mt="24px" display="flex" alignItems="center" columnGap="16px">
        <Text font="Header/H3">Предложение</Text>
        <Box h="24px" background="#D92020" px="8px" borderRadius="4px" display="flex" alignItems="center">
          <Text font="Caption/Caption 1" color="Neutral/Neutral 00">
            Отклонено
          </Text>
        </Box>
      </Box>

      <Box display="flex" mt="16px" columnGap="32px">
        <Box w="208px">
          <InputField label="Дата выхода на работу" value={formatDate(offer.firstWorkDay)} type="text" disabled />
        </Box>
        <Box w="288px">
          <InputField label="Должность" value={offer.jobTitle} type="text" disabled />
        </Box>
      </Box>
      <Box mt="16px">
        <Box w="100%">
          <TextField
            label="Текст приглашения"
            value={INVITATION_TEXT}
            disabled
            color="red"
          />
        </Box>
      </Box>
      {rejectionReasonText && (
        <Box mt="16px">
          <Box w="100%">
            <InputField type="text" label="Причина отказа" value={rejectionReasonText} disabled />
          </Box>
        </Box>
      )}
      {rejectionCommentText && (
        <Box mt="16px">
          <Box w="100%">
            <TextField label="Пояснение" value={rejectionCommentText} disabled />
          </Box>
        </Box>
      )}
    </StyledWrapper>
  );
});
