import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CabinetStatus, Consent, DisplayedLink, Offer, Profile } from '#api/dtos';
import { useApiCall, useApiQuery } from '#api/useApiCall';

import { showApiError } from '#utils/notifications';
import { userContext, UserProfileContextStore, UserProfileContextValue } from './UserProfileContext';
import { UserProfile } from './types';
import { makeSelectors } from './selectors';
import { RecursivePartial } from '#utils/types';
import { FilesLinksResponseBody } from './useDisplayedLinks';
import { ApiCall, ApiCallState } from '#api/apiCallState';
import { remove, save } from '#utils/storage';
import {useQuery} from "#utils/useQuery";

const getPageToRedirect = ({ profileStatus /* , employmentStatus */ }: CabinetStatus /* currentPage?: string */) => {
  switch (true) {
    case profileStatus === 'REGISTERED':
      return 'profile';
    case profileStatus === 'NOT_REGISTERED':
      return 'register';
    case profileStatus === 'MUST_VERIFY':
      return 'verify-email';
    default:
      return 'error/404';
  }
};

export interface UserProviderProps {
  children: React.ReactNode;
}

export function UserProvider({ children }: UserProviderProps) {
  const history = useHistory();
  const q = useQuery();

  const [profile, loadProfile] = useApiQuery<UserProfile>('/user/profile', {
    request: { method: 'GET' /* , credentials: 'include'  */ },
    onError: showApiError,
  });

  const [consents, loadConsents] = useApiQuery<Consent[]>('/consents', {
    request: { method: 'GET' /* , credentials: 'include' */ },
    onError: showApiError,
  });

  const [status, loadStatus] = useApiQuery<CabinetStatus>('/status', {
    request: { method: 'GET' /* , credentials: 'include' */ },
    onError: showApiError,
  });

  const [offer, loadOffer] = useApiQuery<Offer>('/offer', {
    request: { method: 'GET' /* , credentials: 'include' */ },
    onError: showApiError,
  });

  const [, requestLogout] = useApiCall<null>('/auth/logout', {
    request: { method: 'POST' /* , credentials: 'include' */ },
    onError: showApiError,
  });

  const [displayedLinks, loadDisplayedLinks] = useApiQuery<FilesLinksResponseBody>('/displayed-links', {
    request: { method: 'GET' /* , credentials: 'include' */ },
    onError: showApiError,
  });

  const [finalStatus, setFinalStatus] = useState(status);
  const [finalProfile, setFinalProfile] = useState(profile);
  const [finalDisplayedLinks, setFinalDisplayedLinks] = useState<ApiCallState<Record<string, DisplayedLink>>>(
    ApiCall.initial(),
  );

  useEffect(() => {
    if (displayedLinks.data) {
      setFinalDisplayedLinks({
        error: null,
        status: 'success',
        data: displayedLinks.data.reduce<Record<string, DisplayedLink>>((acc, curr) => {
          acc[curr.code] = curr;
          return acc;
        }, {}),
      });
    }
  }, [displayedLinks]);

  useEffect(() => {
    setFinalStatus(() => {
      return status;
    });
  }, [status]);

  useEffect(() => {
    setFinalProfile(() => {
      return profile;
    });
  }, [profile]);

  const saveProfile = useCallback(() => {
    console.log('TODO: postProfile');
  }, []);

  const logout = useCallback(() => {
    requestLogout().then((response) => {
      if (response.status === 'success') {
        // todo: clear user data etc
        remove('email:login');
        remove('showModal');
        history.push('/');
        location.reload();
      } else {
        console.log('TODO: handle logout error', response.error);
      }
    });
  }, [history, requestLogout]);

  const login = useCallback(
    async (email: string) => {
      save('email:login', email);
      const statusResult = await loadStatus();
      if (statusResult.status === 'success' && statusResult.data) {
        const targetUrl = q.get("targetUrl");
        if(targetUrl){
          history.push("/"+targetUrl);
        }else{
          history.push(`/${getPageToRedirect(statusResult.data)}`);
        }
      } else {
        console.warn('login :: unable to get status', statusResult);
      }
    },
    [history, loadStatus, q],
  );

  const actions = useMemo(
    () => ({
      loadDisplayedLinks,
      loadConsents,
      loadStatus,
      saveProfile,
      loadProfile,
      loadOffer,
      login,
      logout,
      setStatusData(nextStatusData: Partial<CabinetStatus>, strategy = 'merge') {
        setFinalStatus((prev) => {
          if (strategy === 'merge') {
            return {
              ...prev,
              data: {
                ...prev.data,
                ...nextStatusData,
              },
            };
          }

          console.warn(`setStatusData :: unknown merge strategy "${strategy}".`);
          return prev;
        });
      },
      setProfileData(nextProfileData: RecursivePartial<Profile>, strategy = 'merge') {
        setFinalProfile((prev) => {
          if (strategy === 'merge') {
            return {
              ...prev,
              data: {
                ...prev.data,
                ...nextProfileData,
                candidate: {
                  ...(prev.data?.candidate || {}),
                  ...(nextProfileData.candidate || {}),
                },
                employment: {
                  ...(prev.data?.employment || {}),
                  ...(nextProfileData.employment || {}),
                },
              },
            };
          }

          console.warn(`setProfileData :: unknown merge strategy "${strategy}".`);
          return prev;
        });
      },
    }),
    [loadDisplayedLinks, loadConsents, loadStatus, saveProfile, loadProfile, loadOffer, login, logout],
  );

  const store = useMemo(() => {
    return { profile: finalProfile, status: finalStatus, consents, offer, displayedLinks: finalDisplayedLinks };
  }, [finalProfile, finalStatus, consents, offer, finalDisplayedLinks]);
  const selectors = useMemo(() => makeSelectors(store as UserProfileContextStore), [store]);

  const value = useMemo(() => {
    return {
      store,
      actions,
      selectors,
    };
  }, [store, actions, selectors]);

  return <userContext.Provider value={value as UserProfileContextValue}>{children}</userContext.Provider>;
}
