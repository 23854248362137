import React from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { T } from '@admiral-ds/react-ui';

import desktop3d from '#assets/desktop3d.svg';
import { Offer } from '#api/dtos';
import { formatDate } from '#utils/date';
import { getDocsStorageBaseUrl } from '#utils/getApiUrl';
import { useDisplayedLinks } from '#user/useDisplayedLinks';

const Text = styled(T)`
  margin: 0;
`;
const SBox = styled(Box)`
  @media (max-width: 935px) {
    flex-direction: column;
    height: auto;
    align-items: flex-start;

    & > [data-element='image'] {
      align-self: center;
    }
  }
`;

export const OfferProcessingDigital = React.memo(({ offer }: { offer: Offer }) => {
  const { getByCode } = useDisplayedLinks({ fetchStrategy: 'no-auto-fetch' });
  const date = formatDate(offer.firstWorkDay);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb="64px" px="32">
      <SBox display="flex" p={32} backgroundColor="#F3F4F6" maxW="932px" borderRadius="24">
        <Box data-element="image" pl="4px" position="relative">
          <img src={desktop3d} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'start'}>
          <Box px="16px" pt="8px" pb="8px" textAlign="center">
            <Text font="Header/H5">Выбрано электронное подписание документов. Подтверждённая дата выхода - {date}</Text>
          </Box>
          <Box px="16px" pt="8px">
            <Text font="Body/Body 2 Long">Как будет проходить электронное подписание:</Text>
          </Box>
          <Box px="16px" pt="8px">
            <Text font="Body/Body 2 Long">
              1. В ближайшее время на Ваш email поступит приглашение от платформы подписания «Автограф».
            </Text>
          </Box>
          <Box px="16px" pt="8px">
            <Text font="Body/Body 2 Long">
              2.{' '}
              <Text font="Body/Body 2 Long" color="Primary/Primary 60 Main">
                <a href={`https://goskey.ru/`} target="_blank">
                  Скачайте
                </a>
              </Text>{' '}
              и установите мобильное приложение Госключ.
            </Text>
          </Box>
          <Box px="16px" pt="8px">
            <Text font="Body/Body 2 Long">
              3. Для подписания требуется{' '}
              <Text font="Body/Body 2 Long" color="Primary/Primary 60 Main">
                <a href={'https://www.gosuslugi.ru/help/faq/login/1'} target="_blank">
                  подтвержденная учётная запись на Госуслугах
                </a>
                .
              </Text>
            </Text>
          </Box>
          <Box px="16px" pt="8px">
            <Text font="Body/Body 2 Long">
              {' '}
              <a href={`${getDocsStorageBaseUrl()}/${getByCode('DIG_SIGN_GUIDE')?.path}`} target="_blank">
                {getByCode('DIG_SIGN_GUIDE')?.title}
              </a>
            </Text>
          </Box>
        </Box>
      </SBox>
    </Box>
  );
});
