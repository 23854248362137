import { CabinetStatus, Profile } from '#api/dtos';
import { RecursivePartial } from '#utils/types';

export interface UserActions {
  loadStatus(): unknown;
  loadConsents(): unknown;
  loadProfile(): Promise<unknown>;
  saveProfile(): unknown;
  logout(): unknown;
  login(email: string): unknown;
  loadOffer(): unknown;
  rejectOffer(): unknown;
  acceptOffer(): unknown;
  completeOffer(): unknown;
  loadDisplayedLinks(): unknown;

  setStatusData(nextStatusData: Partial<CabinetStatus>, strategy?: 'merge'): unknown;
  setProfileData(nextProfileData: RecursivePartial<Profile>, strategy?: 'merge'): unknown;
}

export function makeInitalUserActions() {
  return {
    actions: {
      login() {
        throw new Error('logout action requires UserContext');
      },
      logout() {
        throw new Error('logout action requires UserContext');
      },
      loadStatus() {
        throw new Error('loadStatus action requires UserContext');
      },
      loadConsents() {
        throw new Error('loadConsents action requires UserContext');
      },
      loadProfile() {
        throw new Error('loadProfile action requires UserContext');
      },
      saveProfile() {
        throw new Error('saveProfile action requires UserContext');
      },
      loadOffer() {
        throw new Error('loadOffer action requires UserContext');
      },
      acceptOffer() {
        throw new Error('acceptOffer action requires UserContext');
      },
      completeOffer() {
        throw new Error('completeOffer action requires UserContext');
      },
      rejectOffer() {
        throw new Error('rejectOffer action requires UserContext');
      },
      loadDisplayedLinks() {
        throw new Error('loadDisplayedLinks action requires UserContext');
      },
      setStatusData() {
        throw new Error('setStatusData action requires UserContext');
      },
      setProfileData() {
        throw new Error('setProfileData action requires UserContext');
      },
    },
  };
}
