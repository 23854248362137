import { QueryClient } from '@tanstack/react-query'; // Create a client

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      enabled: false,
      refetchOnWindowFocus: false
    },
  },
});
