import { useApiCall } from '#api/useApiCall';
import { extractFilenameFromHeader, saveAs } from '#utils/download';
import { Box } from '@welcome-ui/box';
import { IDocumentUi } from '../models';
import { T } from '@admiral-ds/react-ui';

interface FileDownloadProps {
  document: IDocumentUi;
}
export function DocumentFileDownload({ document }: FileDownloadProps) {
  const [downloadFileState, downloadFile] = useApiCall<{ blob: Blob; fileName: string }>(`/documents`, {
    request: {
      method: 'GET',
      headers: {
        Accept: 'application/octet-stream',
      },
    },
    async prepareResponseData(response) {
      return {
        blob: await response.blob(),
        fileName: extractFilenameFromHeader(response.headers.get('Content-Disposition') || 'file'),
      };
    },
  });

  const isSaving = downloadFileState.status === 'awaiting'

  return (
    <button
      style={{
        cursor: isSaving ? 'default' : 'pointer',
        border: 'none',
        background: 'transparent',
        padding: 0,
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: `translateY(-12px)`,
        outline: 'none',
      }}
    >
      <Box pl="16px">
        <Box>
          <T
            onClick={async () => {
              if (!isSaving && document.uiStatus === 'Uploaded') {
                const { status, data } = await downloadFile({ query: `/${document.id}` });
                if (status === 'success' && data) {
                  saveAs(data.blob, data.fileName);
                }
              }
            }}
            font="Body/Body 2 Long"
            color="Neutral/Neutral 90"
          >
            {document.name.split('.')[0]}
          </T>
        </Box>
      </Box>
    </button>
  );
}
