import { useEffect, useMemo } from 'react';

import { useUserContext } from '#user/UserProfileContext';

export interface UseStatusProps {
  autoFetch?: boolean;
}

const OFFER_STATUSES = ['OFFER_RECEIVED', 'OFFER_ACCEPTED', 'OFFER_ACCEPTING', 'OFFER_REJECTED'] as const;
export const EMPLOYMENT_STATUSES = [
  'INVITED',
  'CONSENT_ACCEPTED',
  'DOCUMENTS_UPLOADING',
  'DOCUMENTS_SENT',
  ...OFFER_STATUSES,
] as const;

export type EmploymentStatus = (typeof EMPLOYMENT_STATUSES)[number];

export const isAnyOfferStatus = (status?: EmploymentStatus | null) =>
  !!status && OFFER_STATUSES.includes(status as (typeof OFFER_STATUSES)[number]);

const isStatusLower = (status: EmploymentStatus, targetStatus: EmploymentStatus) => {
  return EMPLOYMENT_STATUSES.indexOf(status) < EMPLOYMENT_STATUSES.indexOf(targetStatus);
};

const isStatusHigherOrEqual = (status: EmploymentStatus, targetStatus: EmploymentStatus) => {
  return EMPLOYMENT_STATUSES.indexOf(status) >= EMPLOYMENT_STATUSES.indexOf(targetStatus);
};

const isStatusHigher = (status: EmploymentStatus, targetStatus: EmploymentStatus) => {
  return EMPLOYMENT_STATUSES.indexOf(status) > EMPLOYMENT_STATUSES.indexOf(targetStatus);
};

const employmentStatusToDocumentsLink = (status: EmploymentStatus | null) => {
  switch (true) {
    case status && isStatusLower(status, 'DOCUMENTS_UPLOADING'):
      return '/consent-accepted';
    case status === 'DOCUMENTS_SENT':
      return '/documents-sent';
    case status === 'OFFER_RECEIVED':
    case status === 'OFFER_ACCEPTING':
    case status === 'OFFER_ACCEPTED':
    case status === 'OFFER_REJECTED':
      return '/documents-sent';
    case status === 'DOCUMENTS_UPLOADING':
    default:
      return '/documents';
  }
};

export function useStatus(props?: UseStatusProps) {
  const { store, actions, selectors } = useUserContext();
  const { autoFetch = true } = props || {};

  const documentsLink: string | null = useMemo(
    () => employmentStatusToDocumentsLink((store.status.data?.employmentStatus as EmploymentStatus) ?? null),
    [store.status.data?.employmentStatus],
  );

  const dataStatus = store.status.status;

  useEffect(() => {
    if (autoFetch) {
      actions.loadStatus();
    }
  }, []);

  const result = useMemo(
    () => ({
      isLoading: dataStatus === 'awaiting',
      isReady: dataStatus === 'success',
      isError: dataStatus === 'failure',
      error: store.status.error,
      employmentStatus: store.status.data?.employmentStatus,
      profileStatus: store.status.data?.profileStatus,

      // side navigation
      documentsDisabled: isStatusLower(store.status.data?.employmentStatus as EmploymentStatus, 'DOCUMENTS_UPLOADING'),
      documentsLink,
      documentsVisible: true,
      offerVisible: isStatusHigherOrEqual(store.status.data?.employmentStatus as EmploymentStatus, 'OFFER_RECEIVED'),

      store,
      actions,
      selectors,
      isEmploymentStatusHigherThan: (status: EmploymentStatus) => isStatusHigher(store.status.data?.employmentStatus as EmploymentStatus, status)
    }),
    [actions, dataStatus, documentsLink, selectors, store],
  );

  return result;
}

export type UseStatusHook = ReturnType<typeof useStatus>;
