import { useContext, createContext, useMemo } from 'react';

import { CabinetStatus, Consent, DisplayedLink, Offer } from '#api/dtos';
import { ApiCall, ApiCallState } from '#api/apiCallState';

import { UserProfile } from './types';
import { makeSelectors } from './selectors';
import { UserActions, makeInitalUserActions } from './actions';

export type UserSelectorts = ReturnType<typeof makeSelectors>;

export type UserProfileContextStore = {
  profile: ApiCallState<UserProfile>;
  status: ApiCallState<CabinetStatus>;
  consents: ApiCallState<Array<Consent>>;
  offer: ApiCallState<Offer>;
  displayedLinks: ApiCallState<Record<string, DisplayedLink>>;
};

export type UserProfileContextValue = {
  store: UserProfileContextStore;
  actions: UserActions;
  selectors: UserSelectorts;
};

export function makeInitalUserContextValue() {
  return {
    store: {
      profile: ApiCall.initial(),
      status: ApiCall.initial(),
      consents: ApiCall.initial(),
      offer: ApiCall.initial(),
      displayedLinks: ApiCall.initial(),
    },
  } as const;
}

export const userContext = createContext<UserProfileContextValue>({
  ...makeInitalUserContextValue(),
  ...makeInitalUserActions(),
  selectors: makeSelectors(null),
});

export function useUserContext() {
  const { store, actions, selectors } = useContext(userContext);

  const ctx = useMemo(() => {
    return { store, actions, selectors };
  }, [store, actions, selectors]);

  return ctx;
}
