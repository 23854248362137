import { useEffect, useMemo } from 'react';

import { useUserContext } from '#user/UserProfileContext';

export interface UseConsentsProps {
  autoFetch?: boolean;
}

export function useConsents(props?: UseConsentsProps) {
  const { store, actions, selectors } = useUserContext();
  const { autoFetch = true } = props || {};

  const dataStatus = store.consents.status;

  useEffect(() => {
    if (autoFetch) {
      actions.loadConsents();
    }
  }, []);

  const result = useMemo(
    () => ({
      isReady: dataStatus === 'success',
      consents: store.consents.data,

      store,
      actions,
      selectors,
    }),
    [actions, dataStatus, selectors, store],
  );

  return result;
}

export type UseConsentsHook = ReturnType<typeof useConsents>;
