import { useQuery } from '#utils/useQuery';
import { Entry } from './Entry';

const useEntryParams = () => {
  const q = useQuery();
  const email = decodeURIComponent(q.get('email') ?? '');
  const otp = decodeURIComponent(q.get('otp') ?? '');

  return { email, otp };
};

export function EntryWithEmail({ mode }: { mode?: 'otp' }) {
  const { email, otp } = useEntryParams();

  return <Entry email={email} otp={otp} mode={mode} />;
}
