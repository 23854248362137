import { useInterval } from '@admiral-ds/react-ui';
import { useState } from 'react';

export interface CountdownProps {
  from: number;
  to?: number;
  step?: number;
}

export const useCountdown = (props: CountdownProps) => {
  const { from, step = 1, to = 0 } = props;
  const [counter, setCounter] = useState(from);

  useInterval(() => {
    setCounter((prev) => Math.max(prev - step, to));
  }, 1000);

  return {
    timeLeft: counter,
    reset() {
      setCounter(from);
    },
  };
};
