import { ApiErrorBody } from '#api/processApiError';
import { enqueueSnackbar } from 'notistack';

export const showApiError = (error: ApiErrorBody) => {
  enqueueSnackbar({
    variant: 'error',
    message: <div>{error.detail}</div>,
  });
};

export const showNotDetailedApiError = (error: ApiErrorBody) => {
  const errorDetails = error?.moreDetails?.errorSpecific?.details;
  const isAnyErrorDetails = Array.isArray(errorDetails) ? errorDetails.length > 0 : false;

  if (isAnyErrorDetails  || !error.detail) return;

  enqueueSnackbar({
    variant: 'error',
    message: <div>{error.detail}</div>,
  });
};
