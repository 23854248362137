import { T, Button } from '@admiral-ds/react-ui';
import { Box } from '@welcome-ui/box';

import { TextField } from '#pages/Entry/components/TextField';
import { FormProps } from '../forms';

export function RequestOTPForm(props: FormProps) {
  const { onSubmitEmail, emailField, isSubmitDisabled, isLoading } = props;
  return (
    <form onSubmit={onSubmitEmail}>
      <Box mt="16">
        <TextField
          {...emailField.getProps()}
          type="email"
          autoComplete="email"
          label="email"
          placeholder="mail@mail.com"
          ata-container-id="textFieldIdTwo"
          className="input-field"
          disabled={isSubmitDisabled}
          loading={isLoading}
        />
      </Box>
      {!emailField.errorText && (
        <T font="Body/Body 2 Short" as="div" color="Neutral/Neutral 50">
          Введите email, на который получили приглашение на регистрацию
        </T>
      )}

      <Box mt="24px">
        <Button dimension="m" style={{ width: '100%' }} type="submit" disabled={isSubmitDisabled} loading={isLoading}>
          Получить одноразовый код
        </Button>
      </Box>
      <Box mt="8" display="flex" justifyContent="center">
        <T font="Caption/Caption 1" as="div">
          На email будет отправлен одноразовый код для входа
        </T>
      </Box>
    </form>
  );
}
