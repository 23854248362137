import { Box } from '@welcome-ui/box';
import { Button, Modal, ModalTitle, ModalContent, ModalButtonPanel, T, CheckBoxProps } from '@admiral-ds/react-ui';
import { SalaryCardIssuingAcceptCheckbox } from '#components/SalaryCardIssuing';

export const SalaryCardIssuingAcceptModal = ({
  onCloseModal,
  checked,
  onChange,
  onSubmit,
}: { onCloseModal: () => void; onSubmit: () => void } & CheckBoxProps) => {
  return (
    <Modal onClose={onCloseModal} dimension="l" closeOnEscapeKeyDown>
      <Box as={ModalTitle} textAlign={'center'}>
        Вы забыли дать согласие
      </Box>
      <Box px={'64px'} as={ModalContent} textAlign={'center'}>
        <T font="Body/Body 1 Long">
          Подробнее познакомиться с преимуществами зарплатной карты ВТБ можно{' '}
          <a
            href="https://www.vtb.ru/personal/karty/zarplatnye/zarplatnyj-proekt/?ysclid=lzk1c61r15203624805"
            target="_blank"
          >
            тут
          </a>
        </T>
      </Box>
      <Box as={ModalButtonPanel} flexDirection={'row'} columnGap={'32px'}>
        <Button appearance="primary" dimension="l" onClick={onSubmit}>
          Отправить
        </Button>
        <SalaryCardIssuingAcceptCheckbox checked={checked} onChange={onChange} />
      </Box>
    </Modal>
  );
};
