import { useCallback, useMemo, useState } from 'react';
import { ValidationTestFn, ValidationResultError, makeValidator, FieldValidator } from './validations';

export type FieldEvents = 'onChange' | 'onBlur' | 'onValidate';
export type ValidatorTestFnsRecord<T> = {
  [k in FieldEvents]: Array<ValidationTestFn<T>>;
};

export type ValidatorsRecord<T> = {
  [k in FieldEvents]: Array<FieldValidator<T>>;
};

export interface UseFieldProps<T = string> {
  errorHeight?: number;
  initialValue: T;
  validators?: Partial<ValidatorTestFnsRecord<T>>;
}

export type FieldStatus = 'success' | 'error' | undefined;

function doValidate<T = string>(validators: Array<FieldValidator<T>>, value: T) {
  return validators.reduce<Array<ValidationResultError>>((errors, validator) => {
    const { error } = validator.validate(value);
    if (error) errors.push({ error });
    return errors;
  }, []);
}

const EMPTY_ARRAY: Array<ValidationResultError> = [];

export function useField<T = string>(props: UseFieldProps<T>) {
  const { initialValue, validators: validatorMakers, errorHeight } = props;
  const [value, setValue] = useState(initialValue);
  const [errors, setErrors] = useState<Array<ValidationResultError>>(EMPTY_ARRAY);
  const [status, setStatus] = useState<FieldStatus>();
  // const [isSubmittable, setIsSubmittable] = useState<boolean>(false);
  // const [isTouched, setIsTouched] = useState<boolean>(false);

  const validators: ValidatorsRecord<T> = useMemo(() => {
    const result: ValidatorsRecord<T> = {
      onChange: [],
      onBlur: [],
      onValidate: [],
    };

    if (!validatorMakers) return result;

    return Object.keys(validatorMakers).reduce<ValidatorsRecord<T>>((result, eventName) => {
      const ent = eventName as FieldEvents;
      result[ent] = (validatorMakers[ent] ?? []).map((validate: ValidationTestFn<T>) => makeValidator(validate)) || [];
      return result;
    }, result);
  }, [validatorMakers]);

  const errorText = useMemo(() => {
    return errors[errors.length - 1]?.error ?? '';
  }, [errors]);

  const isSubmittable = !errorText;

  const onChange = useCallback(
    (newValue: T) => {
      const nextErrors = doValidate(validators.onChange, newValue);

      if (nextErrors.length) setStatus('error');
      else setStatus(undefined);

      setErrors(nextErrors);
      setValue(newValue);
    },
    [validators],
  );

  const onBlur = useCallback(() => {
    const nextErrors = doValidate(validators.onBlur, value);

    if (nextErrors.length) setStatus('error');
    else setStatus(undefined);

    setErrors(nextErrors);
  }, [validators, value]);

  const validate = useCallback(() => {
    const nextErrors = doValidate(validators.onValidate, value);

    if (nextErrors.length) setStatus('error');
    else setStatus(undefined);

    setErrors(nextErrors);

    return nextErrors;
  }, [validators, value]);

  const result = useMemo(() => {
    return {
      getCheckboxProps() {
        return {
          checked: !!value,

          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.checked as T);
          },
          onBlur,

          status,
          error: !!errorText,
          errorText,
          errorHeight,
        };
      },
      getProps() {
        return {
          value,

          onChange,
          onBlur,

          status,
          errorText,
          errorHeight,
        };
      },
      value,

      pushError(err: ValidationResultError) {
        setErrors((errors) => [...(errors || []), err]);
      },
      clearErrors() {
        setErrors([]);
      },

      onChange,
      onBlur,
      validate,

      errors,
      errorText,
      status,
      isSubmittable,
    };
  }, [errorHeight, errorText, errors, isSubmittable, onBlur, onChange, status, validate, value]);

  return result;
}

export type UseFieldResult<T> = ReturnType<typeof useField<T>>;
