import { useMemo } from 'react';
import { Spinner, T } from '@admiral-ds/react-ui';
import { Box } from '@welcome-ui/box';
import styled from 'styled-components';

import { NeedHelp } from '#pages/Entry/components/NeedHelp';
import { RegisterForm } from './Forms/RegisterForm';
import { useStatus } from '#user/useStatus';

import sideImg from '#assets/side.png';
import { WelcomeImage } from '../../components/WelcomeImage';
import { useDisplayedLinks } from '#user/useDisplayedLinks';
import { load } from '#utils/storage';

const RootContainer = styled.div`
  display: grid;

  @media (min-width: 769px) {
    grid-template-columns: 2fr 3fr;
    gap: 6em;
  }

  @media (max-width: 1024px) {
    gap: 1.5em;
  }

  @media (max-width: 768px) {
    grid-template-rows: auto auto;
    gap: 1em;
  }
`;

export function RegisterScreen() {
  // const { isLoading, error } = useStatus();

  useDisplayedLinks({
    fetchStrategy: 'fetch-initial',
  });

  const loadingElement = useMemo(() => {
    return (
      <Box w="100%" mb="40px">
        <Box mt="32px" display="flex" justifyContent="center">
          <Spinner dimension="l" />
        </Box>
      </Box>
    );
  }, []);

  const email = load('email:login');

  // if (!email) console.warn('RegisterScreen no email');
  // if (!error) console.warn('RegisterScreen error', error);

  // if (isLoading) return loadingElement;

  return (
    <RootContainer>
      <WelcomeImage src={sideImg} />
      <Box display="flex" flexDirection="column" padding="12px" mt="12px" textAlign="left" maxW="408px">
        <Box mb="12">
          <T font="Header/H4">Укажите ФИО для подтверждения учетной записи</T>
        </Box>
        <RegisterForm email={email ?? ''} />
        <Box pt="16px" display="flex" justifyContent="center" alignItems="center">
          <NeedHelp />
        </Box>
      </Box>
    </RootContainer>
  );
}
