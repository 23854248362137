import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { LIGHT_THEME, FontsVTBGroup, DropdownProvider, ToastProvider, Toast } from '@admiral-ds/react-ui';
import { SnackbarProvider } from 'notistack';

import { App } from './App.tsx';
import './index.css';

async function main() {
  if (process.env.NODE_ENV === 'development' || import.meta.env.VITE_FORCE_API_MOCKS) {
    const { worker } = await import('./api/mocks/browser');
    worker.start();
  }

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  (window as any).version = (await import('../package.json')).version

  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <ThemeProvider theme={LIGHT_THEME}>
        <SnackbarProvider />
        <ToastProvider>
          <DropdownProvider>
            <FontsVTBGroup />
            <App />
          </DropdownProvider>
          <Toast style={{ top: 120, right: 64, maxWidth: '800px', width: '100%' }} />
        </ToastProvider>
      </ThemeProvider>
    </React.StrictMode>,
  );
}

main();
