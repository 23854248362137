import { useCallback, useMemo } from 'react';
import { UseMutationOptions, UseQueryOptions, UseQueryResult, useMutation, useQuery } from '@tanstack/react-query';

import { ApiCallFailure, ApiCallStatus, ApiCallSuccess } from './apiCallState';
import { ApiCallParams, requestApi } from './fetcher';
import { ErrorFields, processApiError } from './processApiError';
import { freezeForTime } from '../ui/globalFreeze';

export type ApiCallResponse<T = unknown, E = unknown> = ApiCallSuccess<T> | ApiCallFailure<E>;

export const FREEZE_TIMEOUT = 30 * 1000;
export interface ApiCallerProps<E = unknown> {
  forceFetch?: boolean;
  invalidateDataOnFetch?: boolean;
  request?: RequestInit;

  errorFields?: ErrorFields;
  onError?(apiCall: ApiCallFailure<E>['error']): void;
  isSuccess?(response: Response): boolean;

  debug?: boolean;
  prepareResponseData?(response: Response): Promise<unknown>;

  onFreezeStart?(): void;
  onFreezeEnd?(): void;
}

const DEFAULT_SUCCESS_STATUS_CODES = [200, 201, 204];
function isDefaultSuccess(response: Response) {
  return DEFAULT_SUCCESS_STATUS_CODES.includes(response.status);
}

// type AnyBody = FormData | Record<string, unknown>;

type CallArg<T = unknown> = {
  body?: T | null;
  query?: string;
};
type ModifyRequestFn = (params: ApiCallParams, callArg: CallArg) => { params: ApiCallParams; url: string };

function makeFn<T, P>(
  url: string,
  {
    prepareResponseData = (response: Response) => response.json(),
    request = {},
    isSuccess = isDefaultSuccess,
    errorFields = {},
    onError = () => void 0,
    onFreezeEnd = () => void 0,
    onFreezeStart = () => void 0,
  }: ApiCallerProps,
  modifyRequest: ModifyRequestFn = (params) => {
    return { url, params };
  },
) {
  return async function fn(callArg: CallArg<P> = {}): Promise<T> {
    let isErrorHandled = false;
    try {
      const { params, url: urlWithModifiers } = modifyRequest({ request }, callArg);

      const response = await requestApi.fetcher(urlWithModifiers, params).then((res) => {
        return res;
      });

      if (isSuccess(response)) {
        try {
          const data = (await prepareResponseData(response)) as T;
          return data;
        } catch (e) {
          return null as T;
        }
      }

      let error: any = null;
      if (response.status === 429) {
        onFreezeStart();
        freezeForTime(FREEZE_TIMEOUT).then(() => onFreezeEnd());
        error = {
          detail: 'Превышено допустимое количество попыток. Страница временно заморожена',
        };
      } else {
        error = await response.json();
      }

      processApiError(error, errorFields);
      onError(error);
      isErrorHandled = true;
      throw new Error(error.detail ?? 'Generic API error');
    } catch (error: any) {
      console.warn(error?.message);
      const message = `Method "${url}" ${error?.message ?? 'Generic API error'}`;

      if (!isErrorHandled) {
        processApiError(error, errorFields);
        onError({
          detail: message,
        });
      }

      throw new Error(message);
    }
  };
}

const makeQueryFn = <T, P>(url: string, props: ApiCallerProps) => makeFn<T, P>(url, props);

const makeMutationFn = <T, P>(url: string, props: ApiCallerProps) =>
  makeFn<T, P>(url, props, (params: ApiCallParams, { body, query }: CallArg) => {
    switch (true) {
      case !body:
        break;
      case body instanceof FormData:
        params.request!.body = body as FormData;
        break;
      case typeof body === 'object':
        params.request!.body = JSON.stringify(body);
        break;
      default:
        params.request!.body = body as BodyInit;
    }

    return { url: url + (query ?? ''), params };
  });

export function useApiCall<T = unknown>(callKey: string, props?: ApiCallerProps & { callProps?: UseMutationOptions }) {
  const {
    prepareResponseData,
    errorFields = {},
    onError,
    request = {},
    isSuccess = isDefaultSuccess,
    callProps = {},
    onFreezeEnd,
    onFreezeStart,
  } = props || {};

  const queryFn = useMemo(
    <P>() =>
      makeMutationFn<T, P>(callKey, {
        prepareResponseData,
        request,
        errorFields,
        isSuccess,
        onError,
        onFreezeEnd,
        onFreezeStart,
      }),
    [callKey, prepareResponseData, errorFields, isSuccess, onError, request, onFreezeEnd, onFreezeStart],
  );

  const mutation = useMutation({
    mutationKey: [callKey],
    mutationFn: queryFn,
    retry: 0,
    ...callProps,
  });

  const call = useCallback(
    async <P>({ body, query }: CallArg<P> = {}) => {
      try {
        const data = (await mutation.mutateAsync({ body, query })) as T;
        return { status: 'success', data };
      } catch (error) {
        return { status: 'failure', error };
      }
    },
    [mutation],
  );

  const result = useMemo(() => {
    const { data, error } = mutation;
    const typedData = data as T;

    const status = ((): ApiCallStatus => {
      // isLoading + !data = fetching
      // !isLoading + data = success
      // !isLoading + !data = initial
      // isLoading + data = refetching

      switch (mutation.status) {
        case 'loading':
          return 'awaiting';
        case 'error':
          return 'failure';
        case 'idle':
          return 'success';
        default:
          return mutation.status;
      }
    })();
    return [{ status, error, data: typedData }, call] as const;
  }, [mutation, call]);

  return result;
}

export function useApiQuery<T = unknown>(callKey: string, props?: ApiCallerProps & { queryProps?: UseQueryOptions }) {
  const {
    prepareResponseData,
    errorFields,
    onError,
    request = {},
    isSuccess = isDefaultSuccess,
    queryProps = {},
  } = props || {};

  const queryFn = useMemo(
    () => makeQueryFn(callKey, { request, prepareResponseData, errorFields, isSuccess, onError }),
    [callKey, errorFields, isSuccess, onError, request, prepareResponseData],
  );

  const query = useQuery({
    queryKey: [callKey],
    queryFn,
    enabled: false,
    retry: 0,
    ...queryProps,
  });

  const call = useCallback(async () => {
    try {
      const { data, error } = await query.refetch();
      if (!error) return { status: 'success', data };
      return { status: 'failure', error };
    } catch (error) {
      return { status: 'failure', error };
    }
  }, [query.refetch]);

  const result = useMemo(() => {
    const { data, error } = query as { data: T; error: UseQueryResult['error'] };

    const status = ((): ApiCallStatus => {
      switch (true) {
        case query.status === 'loading' && query.fetchStatus === 'fetching':
          return 'awaiting';
        case query.status === 'error':
          return 'failure';
        case query.status === 'success':
          return 'success';
        default:
          return 'initial';
      }
    })();
    return [{ status, error, data }, call] as const;
  }, [
    query.status,
    query.fetchStatus,
    query.isFetched,
    query.isFetching,
    query.isError,
    query.isFetchedAfterMount,
    query.isLoading,
    query.isLoadingError,
    query.isInitialLoading,
    query.isRefetching,
    query.isRefetchError,
    query.isSuccess,
    callKey,
    call,
  ]);

  return result;
}
