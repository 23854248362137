import { Box } from '@welcome-ui/box';
import { Modal, ModalTitle, ModalContent, T, Button } from '@admiral-ds/react-ui';

export const WelcomeModal = ({ onCloseModal }: { onCloseModal: () => void }) => {
  return (
    <Modal onClose={onCloseModal} style={{ maxWidth: 'calc(100% - 48px)' }}>
      <ModalTitle id="modal-title">Вы успешно прошли регистрацию в Личном кабинете кандидата!</ModalTitle>
      <ModalContent>
        <Box>
          <T font="Body/Body 1 Long" color="Neutral/Neutral 90">
            О дальнейших действиях мы проинформируем Вас дополнительно.
          </T>
          <Box mt={32} display="flex" justifyContent="end">
            <Button dimension="m" type="button" onClick={onCloseModal} disabled={false}>
              Хорошо
            </Button>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};
