import { Box, BoxProps } from '@welcome-ui/box';
import { T } from '@admiral-ds/react-ui';

export interface ErrorBlockProps extends BoxProps {
  logoSrc: string | null;
  logoWidth?: string;
  title: string;
  children: React.ReactNode;
}
export function ErrorBlock({ title, logoSrc, children, ...boxProps }: ErrorBlockProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignContent="center"
      justifyItems="center"
      alignSelf="center"
      minH="520px"
      {...boxProps}
    >
      {logoSrc && (
        <Box display="flex" justifyContent="center">
          <img src={logoSrc} alt="Ошибка" />
        </Box>
      )}
      <T as="h2" font="Header/H5" color="Neutral/Neutral 90">
        {title}
      </T>
      <Box>{children}</Box>
    </Box>
  );
}
