import React, { useContext } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Box } from '@welcome-ui/box';
import { Checkbox, T, FileInput, FileItem, RenderFileInputProps, Button } from '@admiral-ds/react-ui';
import { IDocumentType, IDocumentUi } from '../models';
import { getMaxPossibleAmountOfFilesToUpload, getNotApplicableText, formatFileType } from '../utils';
import { DATE_TIME } from '#utils/date';
import { userContext } from '#user/UserProfileContext';
import { DocumentFileDownload } from './FileDownload';

const Text = styled(T)`
  margin: 0;
  ${({ lineHeight }) => (lineHeight ? `line-height: ${lineHeight}` : '')}
`;

const StyledPack = styled.div`
  & + & {
    margin-top: 8px;
  }
`;

const DocumentWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
  width: 100%;
  padding: 16px 24px 32px;
  box-sizing: border-box;
  background: ${(p) => p.theme.color['Neutral/Neutral 05']};
  border-radius: 24px;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 16px 24px 24px;
  }
`;

const StyledFileInputWrapper = styled(Box)`
  & > div {
    min-width: unset;
  }
`;

export const DocumentTypeToClarify = React.memo(
  (props: {
    documentType: IDocumentType;
    documents: IDocumentUi[];
    fileExtensions: string[];
    handleDocumentUpload: (file: File, vacancyId: string, euin: string) => void;
    handleDocumentRemove: (documentId: string) => void;
    notApplicableDocumentTypes: string[];
    handleNonApplicableDocumentTypeToggle: (euin: string) => void;
  }) => {
    const {
      documentType,
      documents,
      fileExtensions,
      handleDocumentRemove,
      handleDocumentUpload,
      notApplicableDocumentTypes,
      handleNonApplicableDocumentTypeToggle,
    } = props;
    const {
      selectors: { getVacancyId },
    } = useContext(userContext);
    const vacancyId = getVacancyId();
    const maxPossibleAmountOfFilesToUpload = getMaxPossibleAmountOfFilesToUpload(documentType, documents);
    const documentNotApplicable = notApplicableDocumentTypes.includes(documentType.euin);
    const documentsOfType = documents.filter((d) => d.euin === documentType.euin);
    const packsDates = Array.from(new Set(documentsOfType.map((d) => d.submitDate).filter(Boolean))).sort((a, b) =>
      dayjs(a).isBefore(dayjs(b)) ? -1 : 1,
    );

    // Документы сгруппированные по датам отправки в банк
    const documentsByPacks = packsDates.map((date) => documentsOfType.filter((d) => d.submitDate === date));
    // Документы которые прикрепляются сейчас
    const documentsWithoutPack = documentsOfType.filter((d) => !d.submitDate);

    return (
      <DocumentWrapper key={documentType.euin}>
        <Box w="100%">
          <Box background="#FFB799" borderRadius="4px" w="fit-content" padding="0 8px">
            <Text font="Caption/Caption 1" color="Neutral/Neutral 90" lineHeight="24px">
              Требуется уточнение
            </Text>
          </Box>
          <Box>
            <Text font="Header/H5">{documentType.title}</Text>
          </Box>
          <Box>
            <Text font="Body/Body 2 Long" color="Neutral/Neutral 90">
              {documentType.description}
            </Text>
          </Box>
          {documentType.mayBeNotApplicable === true ? (
            <Box>
              <label>
                <Box mt="18px" display="flex" columnGap="8px" cursor="pointer">
                  <Checkbox
                    dimension="m"
                    onChange={() => {
                      handleNonApplicableDocumentTypeToggle(documentType.euin);
                    }}
                    checked={notApplicableDocumentTypes.includes(documentType.euin)}
                  />
                  <T font="Body/Body 1 Short" as="div">
                    {getNotApplicableText(documentType.euin)}
                  </T>
                </Box>
              </label>
            </Box>
          ) : null}
        </Box>
        <Box w="100%">
          {documentsByPacks.map((packOfDocuments, i) => {
            return (
              <StyledPack key={i}>
                <Text font="Body/Body 2 Short">
                  Отправлено {dayjs(packOfDocuments[0].submitDate).format(DATE_TIME)}
                </Text>
                {packOfDocuments.map((document) => (
                  <Box key={document.id} mt="-8px" opacity={documentNotApplicable ? 0.5 : 0.75}>
                    <FileItem
                      fileType={document.mimeType}
                      formatFileTypeInfo={formatFileType}
                      fileId={document.id}
                      fileName={document.name.split('.')[0]}
                      fileSize={document.size}
                      status={document.uiStatus}
                      dimension="m"
                    />
                  </Box>
                ))}
              </StyledPack>
            );
          })}

          {/* Файлы в процессе загрузки и неподтвержденные */}
          {documentsWithoutPack.length ? (
            <Box mt="8px">
              <Text font="Body/Body 2 Short">Загружено и не отправлено</Text>
              {documentsWithoutPack.map((document) => {
                return (
                  <Box key={document.id} mt="-8px" opacity={documentNotApplicable ? 0.5 : 1} position="relative">
                    <DocumentFileDownload document={document} />
                    <FileItem
                      style={{
                        color: 'transparent',
                      }}
                      fileType={document.mimeType}
                      fileId={document.id}
                      fileName={document.name.split('.')[0]}
                      fileSize={document.size}
                      status={document.uiStatus}
                      onCloseIconClick={
                        document.uiStatus !== 'Loading'
                          ? () => {
                              handleDocumentRemove(document.id);
                            }
                          : undefined
                      }
                      dimension="m"
                    />
                  </Box>
                );
              })}
            </Box>
          ) : null}

          <StyledFileInputWrapper mt="16px">
            <FileInput
              dimension="m"
              accept={fileExtensions.map((e) => '.' + e).join(', ')}
              multiple
              onChange={(e) => {
                const maxAmountOfFilesToUpload = getMaxPossibleAmountOfFilesToUpload(documentType, documents);
                Array.from(e?.target?.files || [])
                  // Не даём через drag and drop загрузить больше положенного
                  .slice(0, maxAmountOfFilesToUpload)
                  .forEach((file: File) => {
                    handleDocumentUpload(file, vacancyId!, documentType.euin);
                  });
                e.target.value = '';
              }}
              disabled={documentNotApplicable || maxPossibleAmountOfFilesToUpload <= 0}
              renderCustomFileInput={(options: RenderFileInputProps) => {
                const handleButtonClick = () => options.onQueryUpload();
                return (
                  <Button
                    onClick={handleButtonClick}
                    dimension="m"
                    appearance="secondary"
                    disabled={documentNotApplicable || maxPossibleAmountOfFilesToUpload <= 0}
                  >
                    Загрузить еще
                  </Button>
                );
              }}
            />
          </StyledFileInputWrapper>
        </Box>
      </DocumentWrapper>
    );
  },
);
