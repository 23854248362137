import React from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { DefaultToastItem, T } from '@admiral-ds/react-ui';

const Text = styled(T)`
  margin: 0;
`;

const Svg = styled.svg`
  vertical-align: middle;
`;

export const FileUploadErrorToast = React.memo((props: { text: string; onRemove: () => void }) => {
  return (
    <DefaultToastItem
      status="error"
      children={
        <Box display="flex" columnGap="12px">
          <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM12.0001 13.6954C12.3591 13.6954 12.6501 13.4043 12.6501 13.0454V7.89915C12.6501 7.54016 12.3591 7.24915 12.0001 7.24915C11.6411 7.24915 11.3501 7.54016 11.3501 7.89915V13.0454C11.3501 13.4043 11.6411 13.6954 12.0001 13.6954ZM12.8499 15.9009C12.8499 16.3703 12.4693 16.7509 11.9999 16.7509C11.5305 16.7509 11.1499 16.3703 11.1499 15.9009C11.1499 15.4315 11.5305 15.0509 11.9999 15.0509C12.4693 15.0509 12.8499 15.4315 12.8499 15.9009Z"
              fill="#FF5C22"
            />
          </Svg>
          <Text font="Body/Body 2 Long">{props.text}</Text>
        </Box>
      }
      isClosable
      onClose={props.onRemove}
    />
  );
});
