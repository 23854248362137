import React from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { T } from '@admiral-ds/react-ui';

import desktop3d from '#assets/certificat.svg';
import { Offer } from '#api/dtos';
import { formatDate } from '#utils/date';

const Text = styled(T)`
  margin: 0;
`;

const SBox = styled(Box)`
  @media (max-width: 935px) {
    flex-direction: column;
    height: auto;
    align-items: flex-start;

    & > [data-element='image'] {
      align-self: center;
    }
  }
`;

export const OfferProcessingPaper = React.memo(({ offer }: { offer: Offer }) => {
  const date = formatDate(offer.firstWorkDay);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb="64px" px="32">
      <SBox display="flex" p={32} backgroundColor="#F3F4F6" maxW="932px" borderRadius="24">
        <Box data-element="image" pl="4px" position="relative">
          <img src={desktop3d} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'start'}>
          <Box px="16px" pt="8px" pb="8px" textAlign="center">
            <Text font="Header/H5">Подтверждённая дата выхода - {date}</Text>
          </Box>
          <Box px="16px" pt="8px">
            <Text font="Body/Body 2 Long">Оформление будет проходить в офисе.</Text>
          </Box>
          <Box px="16px" pt="24px">
            <Text font="Body/Body 2 Long">
              Вам поступит письмо с информацией об оформлении и перечнем документов, которые необходимо будет взять с
              собой в офис.
            </Text>
          </Box>
          <Box px="16px" pt="24px">
            <Text font="Body/Body 2 Long">Ожидайте информации от рекрутера на Ваш email.</Text>
          </Box>
        </Box>
      </SBox>
    </Box>
  );
});
