import React, { useState, useRef, useCallback } from 'react';
import { Box, BoxProps } from '@welcome-ui/box';
import { Header } from '../Header';
import { SideNavigation } from '../SideNavigation';
import { Footer } from '../Footer';
import { useClickOutside } from '@admiral-ds/react-ui';
import { useDisplayedLinks } from '#user/useDisplayedLinks';

export const DefaultPageLayout = React.memo((props: { children: React.ReactNode; contentBoxProps?: BoxProps }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const sideNavigationRef = useRef<HTMLDivElement | null>(null);
  const menuButtonRef = useRef<HTMLButtonElement | null>(null);

  useDisplayedLinks({
    fetchStrategy: 'fetch-initial',
  });

  const toggleMenuOpened = useCallback(() => {
    setIsMenuOpen((opened) => !opened);
  }, []);

  useClickOutside([sideNavigationRef, menuButtonRef], () => {
    if (sideNavigationRef.current && isMenuOpen) toggleMenuOpened();
  });

  return (
    <Box minH="100vh" display="flex" flexDirection="column">
      <Header ref={menuButtonRef} onMenuClick={toggleMenuOpened} isMenuOpen={isMenuOpen} />
      <Box display="flex" w="100%" flexGrow={1} alignContent="stretch" position="relative">
        <SideNavigation ref={sideNavigationRef} isOpen={isMenuOpen} toggleIsOpen={toggleMenuOpened} />
        <Box w="100%" display="flex" justifyContent="center" {...props.contentBoxProps}>
          {props.children}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
});
