import React from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { T } from '@admiral-ds/react-ui';
import { Link } from '#components/Link';
import { getDocsStorageBaseUrl } from '#utils/getApiUrl';
import { useDisplayedLinks } from '#user/useDisplayedLinks';

const Text = styled(T)`
  margin: 0;
`;

const FooterWrapper = styled(Box)`
  padding: 26px 56px;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    height: auto;
    align-items: flex-start;

    padding-left: 24px;
    padding-right: 24px;

    & > div {
      text-align: left;
    }
  }
`;

const SUPPORT_EMAIL = 'support@digidoc.ru';

export const Footer = React.memo(() => {
  const { getByCode } = useDisplayedLinks({ fetchStrategy: 'no-auto-fetch' });
  const usr = getByCode('USER_AGREEMENT');
  const pdt = getByCode('PERS_DATA_TRANSFER');

  return (
    <FooterWrapper
      w="100%"
      display="flex"
      gap="16px"
      boxSizing="border-box"
      justifyContent="space-between"
      background="#F3F4F6"
    >
      <Box>
        <Box>
          <Link href={`${getDocsStorageBaseUrl()}/${usr?.path}`} target="_blank">
            {usr?.title}
          </Link>
        </Box>

        <Box>
          <Link href={`${getDocsStorageBaseUrl()}/${pdt?.path}`} target="_blank">
            {pdt?.title}
          </Link>
        </Box>
      </Box>
      <Box textAlign="end">
        <Box display="flex" alignItems="center" gap="6px">
          <Text font="Caption/Caption 1">Техническая поддержка: </Text>
          <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
        </Box>
        <Text font="Caption/Caption 1">АО «Цифровые документы» ИНН 7720852788</Text>
      </Box>
    </FooterWrapper>
  );
});
