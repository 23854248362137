import React, { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { T, Spinner } from '@admiral-ds/react-ui';

import { useProfile } from '#user/useProfile';
import { ProfileForm } from './ProfileForm';
import { useConsents } from '#user/useConsents';
import { WelcomeModal } from './components/WelcomeModal';
import { load, remove } from '#utils/storage';

const ProfileHeader = () => {
  return (
    <Box mt="24px">
      <Text font="Header/H3">Профиль</Text>
    </Box>
  );
};

const Text = styled(T)`
  margin: 0;
`;

const Loading = () => (
  <Box w="928px" mb="40px" ml="56px">
    <ProfileHeader />
    <Box mt="32px" display="flex" justifyContent="center">
      <Spinner dimension="l" />
    </Box>
  </Box>
);

const ProfileWrapper = styled(Box)`
  width: 428px;
  margin-left: 56px;

  @media (max-width: 639px) {
    width: 100%;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const Profile = React.memo(() => {
  const [modal, setModal] = useState<'WelcomeModal' | null>(() => (load('showModal') as 'WelcomeModal') || null);
  const { consents, isReady: isReadyConsents } = useConsents();
  const { profile } = useProfile();

  const formConsents = useMemo(() => {
    const reserve = !!consents?.find((v) => v.code === 'RESERVE' && !!v.accepted) ?? false;
    const vacancy = !!consents?.find((v) => v.code === 'VACANCY' && !!v.accepted) ?? false;

    return { reserve, vacancy };
  }, [consents]);

  const onCloseModal = useCallback(() => {
    setModal(null);
    remove('showModal');
  }, []);

  if (profile.isLoading || !profile.data || !isReadyConsents) {
    return <Loading />;
  }

  return (
    <ProfileWrapper mb="40px">
      {modal === 'WelcomeModal' && <WelcomeModal onCloseModal={onCloseModal} />}
      <ProfileHeader />
      <Box mt="32px">
        <ProfileForm consents={formConsents} />
      </Box>
    </ProfileWrapper>
  );
});
