import { useEffect, useMemo } from 'react';

import { useUserContext } from '#user/UserProfileContext';

export interface UseProfileProps {
  autoFetch?: boolean;
}

export function useProfile(props?: UseProfileProps) {
  const { store, actions, selectors } = useUserContext();
  const { autoFetch = true } = props || {};

  useEffect(() => {
    if (autoFetch) {
      actions.loadProfile();
    }
  }, []);

  const result = useMemo(
    () => ({
      profile: {
        isLoading: store.profile.status === 'awaiting',
        data: store.profile.data,
        error: store.profile.error,
      },

      // REGISTERED / NOT_REGISTERED
      status: store.profile.data?.status ?? null,

      store,
      actions,
      selectors,
    }),
    [actions, selectors, store],
  );

  return result;
}

export type UseProfileHook = ReturnType<typeof useProfile>;
