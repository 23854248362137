import { Box, BoxProps } from '@welcome-ui/box';
import { T } from '@admiral-ds/react-ui';

export const SalaryCardAdvantage = (props: BoxProps) => {
  const font = 'Body/Body 2 Long';
  return (
    <Box {...props}>
      <T font={font} color="Neutral/Neutral 90">
        Все сотрудники ВТБ пользуются зарплатной картой ВТБ, которая дает множество{' '}
        <T font={font} color="Primary/Primary 60 Main">
          <a
            href="https://www.vtb.ru/personal/karty/zarplatnye/zarplatnyj-proekt/?ysclid=lzk1c61r15203624805"
            target="_blank"
          >
            преимуществ
          </a>
        </T>
      </T>
    </Box>
  );
};
