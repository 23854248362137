import { Box, BoxProps } from '@welcome-ui/box';
import { CheckBoxProps, Checkbox, T, TProps } from '@admiral-ds/react-ui';
import { useDisplayedLinks } from '#user/useDisplayedLinks';
import { getDocsStorageBaseUrl } from '#utils/getApiUrl';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
  flex-shrink: 0;
`;

export const SalaryCardIssuingAcceptCheckbox = ({
  documentNameProps = {},
  wrapperProps = {},
  ...props
}: CheckBoxProps & {
  documentNameProps?: { prefix?: string } & Partial<Pick<TProps, 'color' | 'font'>>;
  wrapperProps?: BoxProps;
}) => {
  const { getByCode } = useDisplayedLinks({ fetchStrategy: 'no-auto-fetch' });
  const {
    font: documentNameFont = 'Body/Body 2 Long',
    prefix: documentNamePrefix = 'Пожалуйста, подтвердите ',
    color: documentNameColor = 'Primary/Primary 60 Main',
  } = documentNameProps;

  return (
    <Box
      w="100%"
      as={'label'}
      cursor={'pointer'}
      display={'flex'}
      flexDirection={'row'}
      columnGap={'8px'}
      {...wrapperProps}
    >
      <StyledCheckbox dimension="m" {...props} />
      <Box display={'flex'} flexDirection={'column'} rowGap={'4px'}>
        <T font={documentNameFont}>
          {documentNamePrefix}
          <T font={documentNameFont} color={documentNameColor}>
            <a href={`${getDocsStorageBaseUrl()}/${getByCode('PERS_DATA_ZP')?.path}`} target="_blank">
              {getByCode('PERS_DATA_ZP')?.title}
            </a>
          </T>
        </T>
        <T font="Body/Body 2 Long" color="Neutral/Neutral 50">
          Зарплатную карту начнём готовить только после принятия решения о вашем трудоустройстве
        </T>
      </Box>
    </Box>
  );
};
