import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';

import { EntryPageLayout } from './components/EntryPageLayout';
import { DefaultPageLayout } from './components/DefaultPageLayout';
import { StatusGate } from './components/StatusGate/StatusGate';

import { UserProvider } from '#user/UserProvider';
import { queryClient } from '#api/query';

// Entry/Register pages
import { Entry, EntryWithEmail } from './pages/Entry';
import { Register } from './pages/Register';

// Error pages
import { ErrorNotFound } from '#pages/ErorNotFound';
import { ErrorMaintenance } from '#pages/ErrorMaintenance';

// Profile pages
import { Profile } from '#pages/Profile';
import { VerifyEmail } from '#pages/VeriftyEmail';

// Documents pages
import { ConsentAccepted } from '#pages/ConsentAccepted';
import { Documents } from '#pages/Documents';
import { DocumentsSent } from '#pages/DocumentsSent';

// Offer pages
import { OfferReceived } from '#pages/OfferReceived';
import { OfferAccepting } from '#pages/OfferAccepting';
import { OfferRejected } from '#pages/OfferRejected';
import { OfferAccepted } from '#pages/OfferAccepted';

export const App = React.memo(() => {
  return (
    <>
      {/** Для dev сервера включаем forceRefresh, иначе не отрабатывает history.push */}
      <BrowserRouter forceRefresh={process.env.NODE_ENV === 'development'}>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <Switch>
              <Route path="/signin">
                <EntryPageLayout>
                  <EntryWithEmail />
                </EntryPageLayout>
              </Route>
              <Route path="/signin-otp">
                <EntryPageLayout>
                  <EntryWithEmail mode="otp" />
                </EntryPageLayout>
              </Route>
              <Route path="/login">
                <EntryPageLayout>
                  <EntryWithEmail />
                </EntryPageLayout>
              </Route>
              <Route path="/register">
                <StatusGate registered={false}>
                  <EntryPageLayout>
                    <Register />
                  </EntryPageLayout>
                </StatusGate>
              </Route>
              <Route path="/error/404">
                <DefaultPageLayout>
                  <ErrorNotFound />
                </DefaultPageLayout>
              </Route>
              <Route path="/error/maintenance">
                <DefaultPageLayout>
                  <ErrorMaintenance />
                </DefaultPageLayout>
              </Route>
              <Route path="/profile">
                <StatusGate registered debug>
                  <DefaultPageLayout contentBoxProps={{ justifyContent: 'flex-start' }}>
                    <Profile />
                  </DefaultPageLayout>
                </StatusGate>
              </Route>
              <Route path="/verify-email">
                <StatusGate mustVerifyEmailCheck={false}>
                  <DefaultPageLayout contentBoxProps={{ justifyContent: 'flex-start' }}>
                    <VerifyEmail />
                  </DefaultPageLayout>
                </StatusGate>
              </Route>
              <Route path="/consent-accepted">
                <StatusGate registered employmentStatuses={['CONSENT_ACCEPTED']}>
                  <DefaultPageLayout>
                    <ConsentAccepted />
                  </DefaultPageLayout>
                </StatusGate>
              </Route>
              <Route path="/documents">
                <StatusGate registered employmentStatuses={['DOCUMENTS_UPLOADING']}>
                  <DefaultPageLayout>
                    <Documents />
                  </DefaultPageLayout>
                </StatusGate>
              </Route>
              <Route path="/documents-sent">
                <StatusGate
                  registered
                  employmentStatuses={[
                    'DOCUMENTS_SENT',
                    'OFFER_RECEIVED',
                    'OFFER_ACCEPTING',
                    'OFFER_ACCEPTED',
                    'OFFER_REJECTED',
                  ]}
                >
                  <DefaultPageLayout contentBoxProps={{ justifyContent: 'flex-start' }}>
                    <DocumentsSent />
                  </DefaultPageLayout>
                </StatusGate>
              </Route>
              <Route path="/offer-received">
                <StatusGate debug={'OFFER_RECEIVED'} registered employmentStatuses={['OFFER_RECEIVED']}>
                  <DefaultPageLayout>
                    <OfferReceived />
                  </DefaultPageLayout>
                </StatusGate>
              </Route>
              <Route path="/offer-accepting">
                <StatusGate debug={'OFFER_ACCEPTING'} registered employmentStatuses={['OFFER_ACCEPTING']}>
                  <DefaultPageLayout>
                    <OfferAccepting />
                  </DefaultPageLayout>
                </StatusGate>
              </Route>
              <Route path="/offer-rejected">
                <StatusGate debug={'OFFER_REJECTED'} registered employmentStatuses={['OFFER_REJECTED']}>
                  <DefaultPageLayout>
                    <OfferRejected />
                  </DefaultPageLayout>
                </StatusGate>
              </Route>
              <Route path="/offer-accepted">
                <StatusGate debug={'OFFER_ACCEPTED'} registered employmentStatuses={['OFFER_ACCEPTED']}>
                  <DefaultPageLayout>
                    <OfferAccepted />
                  </DefaultPageLayout>
                </StatusGate>
              </Route>
              <Route path="/">
                <EntryPageLayout>
                  <Entry />
                </EntryPageLayout>
              </Route>
            </Switch>
          </UserProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </>
  );
});
