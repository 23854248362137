import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useToast } from '@admiral-ds/react-ui';
import { getApiUrl } from '#utils/getApiUrl';
import { useApiQuery } from '#api/useApiCall';
import { defaultRequestInit } from '#api/fetcher';
import {
  checkIfAllRequiredDocumentsAreAttached,
  checkIfFileExtensionIsValid,
  checkIfFileSizeIsValid,
  convertDocumentsDtoStructureToArray,
} from './utils';
import { IDocumentUi, IDocumentType, IDocumentsByType } from './models';
import { FileUploadErrorToast } from './components/FileUploadErrorToast';

const baseUrl = getApiUrl();

export const useDocuments = () => {
  const [documentTypes, setDocumentTypes] = useState<IDocumentType[]>([]);
  const [documents, setDocuments] = useState<IDocumentUi[]>([]);
  const [mimeTypes, setMimeTypes] = useState<string[]>();
  const [fileExtensions, setFileExtensions] = useState<string[]>();
  const [isInitalLoading, setIsInitialLoading] = useState(true);
  const [notApplicableDocumentTypes, setNotApplicableDocumentTypes] = useState<string[]>([]);

  const [fetchDocumentTypesState, fetchDocumentTypes] = useApiQuery<IDocumentsByType[]>('/document-types');
  const [fetchDocumentsState, fetchDocuments] = useApiQuery<IDocumentsByType[]>('/documents');
  const [fetchSettingsMimeTypesState, fetchSettingsMimeTypes] = useApiQuery<{ 'mime-types': string[] }>(
    '/settings?name=mime-types',
  );
  const [fetchSettingsFileExtensionsState, fetchSettingsFileExtensions] = useApiQuery<{ 'file-extensions': string[] }>(
    '/settings?name=file-extensions',
  );

  useEffect(() => {
    if (
      fetchDocumentTypesState.error ||
      fetchDocumentsState.error ||
      fetchSettingsFileExtensionsState.error ||
      fetchSettingsMimeTypesState.error
    ) {
      const toastId = uuidv4();
      toasts.addToastItem({
        renderToast: () => (
          <FileUploadErrorToast
            text="Возникла ошибка при загрузке страницы"
            onRemove={() => {
              toasts.removeById(toastId);
            }}
          />
        ),
        id: toastId,
      });
    }
  }, [
    fetchDocumentTypesState.error,
    fetchDocumentsState.error,
    fetchSettingsFileExtensionsState.error,
    fetchSettingsMimeTypesState.error,
  ]);

  const toasts = useToast();

  useEffect(() => {
    (async () => {
      try {
        const [documentTypes, documents, mimeTypes, fileExtensions] = (
          await Promise.all([
            fetchDocumentTypes(),
            fetchDocuments(),
            fetchSettingsMimeTypes(),
            fetchSettingsFileExtensions(),
          ])
        ).map(({ data /* , error, status */ }) => data) as [
          IDocumentType[],
          IDocumentsByType[],
          { 'mime-types': string[] },
          { 'file-extensions': string[] },
        ];

        setMimeTypes(mimeTypes?.['mime-types'] || []);
        setDocumentTypes(documentTypes || []);
        setFileExtensions(() => {
          const loweredFileExtensions = (fileExtensions['file-extensions'] || []).map((e) => e.toLowerCase());
          return loweredFileExtensions;
        });

        setDocuments(convertDocumentsDtoStructureToArray(documents || []));
        setIsInitialLoading(false);
        setNotApplicableDocumentTypes(() => {
          const result: string[] = [];
          (documents || []).forEach((d) => {
            if (d.inapplicable) {
              result.push(d.euin!);
            }
          });
          return result;
        });
      } catch (error) {
        // TODO: alert?
        console.error(error);
      }
    })();
  }, []);

  const handleDocumentUpload = useCallback(
    (file: File, vacancyId: string, euin: string) => {
      (async () => {
        const uploadingFileTempId = uuidv4();
        try {
          if (!mimeTypes?.includes(file.type) || !checkIfFileExtensionIsValid(file.name, fileExtensions!)) {
            const toastId = uploadingFileTempId;
            toasts.addToastItem({
              renderToast: () => (
                <FileUploadErrorToast
                  text={`Недопустимый формат файла "${file.name}"`}
                  onRemove={() => {
                    toasts.removeById(toastId);
                  }}
                />
              ),
              id: toastId,
            });

            return;
          }

          if (!checkIfFileSizeIsValid(file.size)) {
            const toastId = uploadingFileTempId;
            toasts.addToastItem({
              renderToast: () => (
                <FileUploadErrorToast
                  text={`Превышен допустимый размер файла "${file.name}"`}
                  onRemove={() => {
                    toasts.removeById(toastId);
                  }}
                />
              ),
              id: toastId,
            });

            return;
          }

          setDocuments((files) => {
            return [
              ...files,
              {
                id: uploadingFileTempId,
                name: file.name,
                size: file.size,
                euin: euin,
                status: undefined,
                uiStatus: 'Loading',
                uiError: undefined,
                mimeType: file.type,
                docSetStatus: 'NEW',
                uploadDate: '',
              },
            ];
          });

          const uploadDocumentFetchCall = await fetch(`${baseUrl}/documents?vacancy-id=${vacancyId}&euin=${euin}`, {
            credentials: 'include',
            headers: {
              Accept: 'application/json',
            },
            method: 'POST',
            body: (() => {
              const data = new FormData();
              data.append('file', file);
              return data;
            })(),
          });
          const uploadedDocument = await uploadDocumentFetchCall.json();

          // Ошибка загрузки
          if (!uploadDocumentFetchCall.ok || (uploadedDocument.status && uploadedDocument.type)) {
            const responseError = new Error('Ошибка при загрузке файла');
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            responseError.response = uploadedDocument;
            throw responseError;
          }

          setDocuments((files) => {
            const result = files.filter((f) => f.id !== uploadingFileTempId);
            result.push({ ...uploadedDocument, uiStatus: 'Uploaded', docSetStatus: 'NEW' });
            return result;
          });
        } catch (err) {
          const toastId = uploadingFileTempId;
          const defaultErrorMessage = `Произошла ошибка при загрузке файла "${file.name}"`;
          toasts.addToastItem({
            renderToast: () => (
              <FileUploadErrorToast
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                text={err.response?.detail || defaultErrorMessage}
                onRemove={() => {
                  toasts.removeById(toastId);
                }}
              />
            ),
            id: toastId,
          });
          setDocuments((files) => files.filter((f) => f.id !== uploadingFileTempId));
          return;
        }
      })();
    },
    [documents, mimeTypes, fileExtensions],
  );

  const handleDocumentRemove = useCallback(
    (documentId: string) => {
      (async () => {
        const document = documents.find((i) => i.id === documentId);
        setDocuments((documents) =>
          documents.map((d) => {
            if (d.id !== documentId) return d;

            return {
              ...d,
              uiStatus: 'Loading',
            };
          }),
        );

        const removedDocumentResponse = await fetch(`${baseUrl}/documents/${documentId}`, {
          ...defaultRequestInit,
          method: 'DELETE',
        });
        let removedDocumentJson: { status: number; type: string } | null = null;
        if (removedDocumentResponse.headers.get('content-type') === 'application/json') {
          removedDocumentJson = await removedDocumentResponse.json();
        }

        // Ошибка при удалении на бэке
        if (!removedDocumentResponse.ok || (removedDocumentJson?.status && removedDocumentJson?.type)) {
          const toastId = uuidv4();
          const defaultErrorMessage = `Ошибка при удалении "${document?.name}"`;
          toasts.addToastItem({
            renderToast: () => (
              <FileUploadErrorToast
                text={removedDocumentJson?.type || defaultErrorMessage}
                onRemove={() => {
                  toasts.removeById(toastId);
                }}
              />
            ),
            id: toastId,
          });

          setDocuments((documents) =>
            documents.map((d) => {
              if (d.id !== documentId) return d;

              return {
                ...d,
                uiStatus: 'Uploaded',
              };
            }),
          );
          return;
        }

        setDocuments((documents) => documents.filter((d) => d.id !== documentId));
      })();
    },
    [documents],
  );

  const handleNonApplicableDocumentTypeToggle = useCallback((euin: string) => {
    (async () => {
      const toggleResponse = await fetch(`${baseUrl}/documents/toggle-applicable/${euin}`, {
        ...defaultRequestInit,
        method: 'POST',
      });
      if (toggleResponse.ok) {
        setNotApplicableDocumentTypes((euins) => {
          if (euins.includes(euin)) {
            return euins.filter((dt) => dt !== euin);
          }

          return [...euins, euin];
        });
      } else {
        const toastId = uuidv4();
        toasts.addToastItem({
          renderToast: () => (
            <FileUploadErrorToast
              text="Не удалось изменить признак неприменимости документа"
              onRemove={() => {
                toasts.removeById(toastId);
              }}
            />
          ),
          id: toastId,
        });
      }
    })();
  }, []);

  return {
    documentTypes,
    documents,
    fileExtensions,
    isInitalLoading,
    handleDocumentUpload,
    handleDocumentRemove,
    haveDocumentsToSubmitToVtb: (documents || []).some((d) => d.docSetStatus === 'NEW'),
    isRequiredDocumentsAttached: checkIfAllRequiredDocumentsAreAttached(
      documentTypes,
      documents,
      notApplicableDocumentTypes,
    ),
    notApplicableDocumentTypes,
    handleNonApplicableDocumentTypeToggle,
  };
};
