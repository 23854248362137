import { useEffect, useMemo } from 'react';

import { useUserContext } from '#user/UserProfileContext';
import { OfferRejectionType, PaperChoiceType, ProcessingTypeData } from '#api/dtos';
import { useStatus } from './useStatus';

export const findOfferRejectionByCode = (code: string | undefined, data?: OfferRejectionType[]) => code ? data?.find((el) => el.code === code) : null;
export const findPaperChoiceByCode = (code: string | undefined, data?: PaperChoiceType[]) => code ? data?.find((el) => el.code === code) : null;

export interface UseOfferProps {
  autoFetch?: boolean;
}

export function useOffer(props?: UseOfferProps) {
  const { autoFetch = true } = props || {};
  const { isReady: isStatusReady, employmentStatus } = useStatus({ autoFetch });
  const { store, actions, selectors } = useUserContext();

  const dataStatus = store.offer.status;

  useEffect(() => {
    if (autoFetch) {
      actions.loadOffer();
    }
  }, []);

  const offerLink = useMemo(() => {
    if (!isStatusReady) return null;

    switch (true) {
      case employmentStatus === 'OFFER_RECEIVED':
        return '/offer-received';
      case employmentStatus === 'OFFER_ACCEPTING':
        return '/offer-accepting';
      case employmentStatus === 'OFFER_REJECTED':
        return '/offer-rejected';
      case employmentStatus === 'OFFER_ACCEPTED': {
        if (!store.offer.data) return null;
        const processingData = store.offer.data.decision?.data as ProcessingTypeData;
        if (processingData?.processingType === 'PAPER') return '/offer-accepted';
        if (processingData?.processingType === 'DIGITAL') return '/offer-accepted';

        return null;
      }
      default:
        return null;
    }
  }, [employmentStatus, isStatusReady, store.offer.data]);

  const result = useMemo(
    () => ({
      isLoading: dataStatus === 'awaiting',
      isReady: dataStatus === 'success',
      isError: dataStatus === 'failure',

      error: store.offer.error,

      offer: store.offer.data,
      offerLink,

      store,
      actions,
      selectors,
    }),
    [actions, dataStatus, offerLink, selectors, store],
  );

  return result;
}

export type UseOfferHook = ReturnType<typeof useOffer>;
