import { useCallback, useMemo, useEffect, useState } from 'react';
import { T, Button, CheckboxField, Link } from '@admiral-ds/react-ui';
import { Box } from '@welcome-ui/box';
import styled from 'styled-components';

import { TextField } from '#pages/Entry/components/TextField';
import { useField } from '#pages/Entry/useField';
import { ValidationResultError } from '#pages/Entry/validations';
import { emailValidators } from '#Entry/validations';

import { getDocsStorageBaseUrl } from '#utils/getApiUrl';
import { useApiCall } from '#api/useApiCall';
import { makeUpdateCandidatePayload } from '#user/editCandidate';
import { showNotDetailedApiError } from '#utils/notifications';
import { useProfile } from '#user/useProfile';
import { Profile } from '#api/dtos';
import { useDisplayedLinks } from '#user/useDisplayedLinks';
import { SalaryCardAdvantage, SalaryCardIssuingAcceptCheckbox } from '#components/SalaryCardIssuing';
import { useStatus } from '#user/useStatus';

const Text = styled(T)`
  margin: 0;
`;

function required<T>(errorText = 'Поле обязательное') {
  return (v: T) => (!v ? errorText : null);
}

export interface ProfileFormProps {
  consents: {
    vacancy: boolean;
    reserve: boolean;
  };
}

export function ProfileForm(props: ProfileFormProps) {
  const { profile, actions } = useProfile({ autoFetch: false });
  const { isEmploymentStatusHigherThan } = useStatus();
  const { consents } = props;
  const [isDisabledReserveVacancyConsent, setIsDisabledReserveVacancyConsent] = useState(
    profile?.data?.candidate?.reserveConsent,
  );
  const [isDisabledSalaryCardIssuing, setIsDisabledSalaryCardIssuing] = useState(
    profile?.data?.candidate?.personalDataTransferZP,
  );
  const { getByCode } = useDisplayedLinks({ fetchStrategy: 'no-auto-fetch' });

  const emailField = useField({
    initialValue: profile.data?.candidate.email || '',
    validators: { onChange: [], onValidate: emailValidators },
  });

  const agreeVacancyField = useField<boolean>({
    initialValue: consents.vacancy,
    errorHeight: 0,
    validators: {
      onBlur: [],
      onChange: [],
      onValidate: [required()],
    },
  });

  const agreeReserveField = useField<boolean>({
    initialValue: profile.data?.candidate.reserveConsent ?? false,
    errorHeight: 0,
    validators: {
      onBlur: [],
      onChange: [],
      onValidate: [],
    },
  });

  const agreeSalaryCardIssuingField = useField<boolean>({
    initialValue: profile.data?.candidate.personalDataTransferZP ?? false,
    errorHeight: 0,
    validators: {
      onBlur: [],
      onChange: [],
      onValidate: [],
    },
  });

  const nameField = useField({
    initialValue: profile.data?.candidate.firstName,
    errorHeight: 26,
    validators: { onChange: [], onValidate: [required('Введите имя')] },
  });

  const surnameField = useField({
    initialValue: profile.data?.candidate.lastName,
    errorHeight: 26,
    validators: { onChange: [], onValidate: [required('Введите фамилию')] },
  });

  const noPatronymicField = useField<boolean>({
    initialValue: profile.data?.candidate.noMiddleName ?? false,
    validators: {
      onBlur: [],
      onChange: [],
      onValidate: [],
    },
  });

  const patronymicField = useField({
    initialValue: profile.data?.candidate.middleName ?? '',
    errorHeight: 25,
    validators: {
      onChange: [],
      onValidate: [noPatronymicField.getCheckboxProps().checked ? () => null : required('Введите отчество')],
    },
  });

  const fields = useMemo(
    () => ({
      'candidate.firstName': nameField,
      'candidate.middleName': patronymicField,
      'candidate.lastName': surnameField,
      'candidate.noMiddleName': noPatronymicField,
      'candidate.vacancyConsent': agreeVacancyField,
      'candidate.reserveConsent': agreeReserveField,
      'candidate.email': emailField,
      'candidate.personalDataTransferZP': agreeSalaryCardIssuingField,
    }),
    [
      emailField,
      agreeReserveField,
      agreeVacancyField,
      nameField,
      noPatronymicField,
      patronymicField,
      surnameField,
      agreeSalaryCardIssuingField,
    ],
  );

  useEffect(() => {
    if (noPatronymicField.value && patronymicField.value !== '') {
      patronymicField.onChange('');
    }
  }, [noPatronymicField.value, patronymicField]);

  const saveProfilePayload = useMemo(() => {
    return {
      candidate: makeUpdateCandidatePayload({
        email: emailField.value,
        firstName: nameField.value,
        middleName: patronymicField.value,
        lastName: surnameField.value,
        noMiddleName: noPatronymicField.value,
        vacancyConsent: agreeVacancyField.value,
        reserveConsent: agreeReserveField.value,
        personalDataTransferZP: agreeSalaryCardIssuingField.value,
      }),
    };
  }, [
    emailField.value,
    nameField.value,
    patronymicField.value,
    surnameField.value,
    noPatronymicField.value,
    agreeVacancyField.value,
    agreeReserveField.value,
    agreeSalaryCardIssuingField.value,
  ]);

  const [saveProfileState, saveProfile] = useApiCall('/user/profile', {
    errorFields: fields,
    request: {
      method: 'PUT',
    },
    onError: showNotDetailedApiError,
  });

  type Field<T = unknown> = ReturnType<typeof useField<T>>;

  // const syncFields = useCallback(() => {
  //   emailField.onChange(profile.data?.candidate.email ?? '');
  //   nameField.onChange(profile.data?.candidate.firstName);
  //   patronymicField.onChange(profile.data?.candidate.middleName ?? '');
  //   surnameField.onChange(profile.data?.candidate.lastName);
  //   noPatronymicField.onChange(profile.data?.candidate.noMiddleName ?? false);
  // }, [
  //   emailField,
  //   profile.data?.candidate.email,
  //   profile.data?.candidate.firstName,
  //   profile.data?.candidate.middleName,
  //   profile.data?.candidate.lastName,
  //   profile.data?.candidate.noMiddleName,
  //   nameField,
  //   patronymicField,
  //   surnameField,
  //   noPatronymicField,
  // ]);

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      const fieldsValidators: Array<() => ValidationResultError[]> = (
        Object.keys(fields) as unknown as Array<keyof typeof fields>
      ).map((fieldName) => (fields[fieldName] as Field).validate);

      const errors = fieldsValidators.reduce((acc: Array<ValidationResultError>, fv) => {
        return [...acc, ...fv()];
      }, []);

      if (errors.length) {
        console.warn('fix validation errors', errors);
        return;
      }

      const response = await saveProfile({ body: saveProfilePayload });
      if (response.status === 'success') {
        if (agreeReserveField.getCheckboxProps().checked) {
          setIsDisabledReserveVacancyConsent(true);
        }
        if (agreeSalaryCardIssuingField.getCheckboxProps().checked) {
          setIsDisabledSalaryCardIssuing(true);
        }
        // enqueueSnackbar({
        //   variant: 'info',
        //   message: 'Профиль успешно обновлён',
        //   autoHideDuration: 1500,
        // });
        actions.setProfileData(response.data as Profile);
        // await loadProfile();
        // syncFields();
      } else {
        console.error('Failed to register', response);
      }
    },
    [fields, saveProfile, saveProfilePayload, actions],
  );

  return (
    <form onSubmit={onSubmit}>
      <Box mb="6">
        <TextField
          {...emailField.getProps()}
          label="Email"
          placeholder="Иванов"
          ata-container-id="textFieldIdTwo"
          className="input-field"
          disabled
          type="text"
        />
      </Box>

      <Box mb="6">
        <TextField
          {...surnameField.getProps()}
          label="Фамилия"
          placeholder=""
          ata-container-id="textFieldIdTwo"
          className="input-field"
          disabled={saveProfileState.status === 'awaiting'}
          type="text"
        />
      </Box>

      <Box mb="6">
        <TextField
          {...nameField.getProps()}
          label="Имя"
          placeholder=""
          ata-container-id="textFieldIdTwo"
          className="input-field"
          disabled={saveProfileState.status === 'awaiting'}
          type="text"
        />
      </Box>

      <Box>
        <TextField
          {...patronymicField.getProps()}
          label="Отчество"
          placeholder=""
          ata-container-id="textFieldIdTwo"
          className="input-field"
          disabled={noPatronymicField.getCheckboxProps().checked || saveProfileState.status === 'awaiting'}
          type="text"
        />
      </Box>

      <Box mb="24">
        <CheckboxField
          {...noPatronymicField.getCheckboxProps()}
          data-container-id="checkboxFieldIdOne"
          id="checkboxFieldId"
          name="checkboxFieldName"
          disabled={saveProfileState.status === 'awaiting'}
        >
          <Text font="Body/Body 1 Short" as="div">
            Нет отчества
          </Text>
        </CheckboxField>
      </Box>
      <Box mb="12">
        <CheckboxField
          {...agreeVacancyField.getCheckboxProps()}
          data-container-id="checkboxFieldIdOne"
          id="checkboxFieldId"
          name="checkboxFieldName"
          disabled
        >
          <Link to="/doc1">
            <Text font="Body/Body 1 Short" color="Primary/Primary 60 Main">
              <a href={`${getDocsStorageBaseUrl()}/${getByCode('VACANCY_CONSENT')?.path}`} target="_blank">
                {getByCode('VACANCY_CONSENT')?.title}
              </a>
            </Text>
          </Link>
        </CheckboxField>
      </Box>
      <Box mb="12">
        <CheckboxField
          {...agreeReserveField.getCheckboxProps()}
          data-container-id="checkboxFieldIdOne"
          id="checkboxFieldId"
          name="checkboxFieldName"
          disabled={isDisabledReserveVacancyConsent || saveProfileState.status === 'awaiting'}
        >
          <Link to="/doc1">
            <Text font="Body/Body 1 Short" color="Primary/Primary 60 Main">
              <a href={`${getDocsStorageBaseUrl()}/${getByCode('RESERVE_CONSENT')?.path}`} target="_blank">
                {getByCode('RESERVE_CONSENT')?.title}
              </a>
            </Text>
          </Link>
        </CheckboxField>
      </Box>

      {isEmploymentStatusHigherThan('DOCUMENTS_UPLOADING') && (
        <Box display={'flex'} flexDirection={'column'} rowGap={'12px'}>
          <SalaryCardAdvantage />
          <SalaryCardIssuingAcceptCheckbox
            {...agreeSalaryCardIssuingField.getCheckboxProps()}
            documentNameProps={{ prefix: '', font: 'Body/Body 1 Short' }}
            wrapperProps={{ p: '16px 24px', borderRadius: '24px', background: '#e5f6ff' }}
            disabled={isDisabledSalaryCardIssuing || saveProfileState.status === 'awaiting'}
          />
        </Box>
      )}

      <Box mt="32" mb="32">
        <Button dimension="m" type="submit" disabled={saveProfileState.status === 'awaiting'}>
          Сохранить
        </Button>
      </Box>
    </form>
  );
}
