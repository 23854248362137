import { getApiUrl } from '#utils/getApiUrl';

export interface ApiCallParams {
  request?: RequestInit;
}

export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const defaultRequestInit = {
  // mode: 'no-cors',
  credentials: 'include',
  method: 'GET',
  headers: defaultHeaders,
} as const;

export interface MakeFetcherProps {
  baseUrl: string;
  requestInit?: RequestInit;
}

export function makeFetcher(props: MakeFetcherProps) {
  const { baseUrl, requestInit: baseRequestInit } = props;

  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };


  return {
    fetcher: async function callFetcher(endpoint: string, params?: ApiCallParams) {
      const { request: init } = params ?? {};

      const controller = new AbortController();
      const { signal } = controller;

      const headers = {
        ...defaultHeaders,
        ...(baseRequestInit?.headers ?? {}),
        ...(init?.headers ?? {}),
      };

      const finalRequstInit: RequestInit = {
        ...defaultRequestInit,
        ...(baseRequestInit ?? {}),
        ...(init ?? {}),
        headers,
        signal,
      };

      const response = await fetch(`${baseUrl}${endpoint}`, finalRequstInit);

      return response;
    },
  };
}

export const requestApi = makeFetcher({
  baseUrl: getApiUrl(),
});

export const makeRequestApi = (endpoint: string, params?: ApiCallParams) => () => requestApi.fetcher(endpoint, params);
