import { Box } from '@welcome-ui/box';

export type ErrorProps = {
  errorText: string;
};

export function Error(props: ErrorProps) {
  const { errorText } = props;
  if (!errorText) return null;

  // pb 8px компенсирует лишние 8px снизу TextField
  // Таким образом нижнему компоненту нужно поставить margin-top - 8px
  return (
    <Box pb="8px" color="#D92020">
      {errorText}
    </Box>
  );
}
