import { T } from '@admiral-ds/react-ui';
import styled from 'styled-components';

// todo: Link из admiral-ds не обрабатывает mailto?
const CustomLink = styled('a')`
  cursor: pointer;
  display: flex;
  pointer-events: auto;
  -webkit-box-align: center;
  align-items: center;
  width: fit-content;
  text-decoration: none;
  position: relative;
  border-radius: 4px;
  color: rgb(0, 98, 255);
  font-family: 'VTB Group UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  font-feature-settings: 'tnum', 'lnum';
  text-rendering: geometricprecision;
`;

const CustomT = styled(T)`
  display: inline-block;
  font-size: 12px;
  padding-right: 4px;
`;

export function NeedHelp() {
  const helpEmail = 'support@digidoc.ru';

  return (
    <>
      <CustomT font="Caption/Caption 1" as="div">
        Нужна помощь?
      </CustomT>{' '}
      <CustomLink href={`mailto:${helpEmail}`}>{helpEmail}</CustomLink>
    </>
  );
}
