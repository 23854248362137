import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { T, Button, InputField, TextField, Checkbox, RadioButton } from '@admiral-ds/react-ui';
import { getDocsStorageBaseUrl } from '#utils/getApiUrl';
import { DigitalSignatureAgitation } from './components/DigitalSignatureAgitation';
import { RefuseDigitalProcessModal } from '#pages/OfferAccepting/components/RefuseDigitalProcessModal';
import { EmploymentDetailsModal } from './components/EmploymentDetailsModal';
import { useApiCall } from '#api/useApiCall';
import { showApiError } from '#utils/notifications';
import { useHistory } from 'react-router-dom';
import { useOffer } from '#user/useOffer';
import { formatDate } from '#utils/date';

import keyImg from '#assets/key.png';
import { INVITATION_TEXT } from '../../constants';
import { useDisplayedLinks } from '#user/useDisplayedLinks';

const Text = styled(T)`
  margin: 0;
`;

const StyledWrapper = styled(Box)`
  & input[type='text'][disabled]:disabled,
  & textarea[disabled]:disabled {
    color: #23262d;
  }
`;

const StyledCheckboxWrapper = styled(Box)`
  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 4px;
  }
`;

const TemplateLink = styled(T)`
  cursor: pointer;
`;

export const OfferAccepting = React.memo(() => {
  const { offer, isReady, actions } = useOffer();
  const { getByCode } = useDisplayedLinks({ fetchStrategy: 'no-auto-fetch' });
  const history = useHistory();
  const [methodOfEmployment, setMethodOfEmployment] = useState<'digital' | 'office' | null>(null);
  const [modal, setModal] = useState<'RefuseDigitalProcessModal' | 'EmploymentDetailsModal' | null>(null);
  const [isThirdPartyConsentGiven, setIsThirdPartyConsentGiven] = useState(false);

  const [completeOfferState, completeOffer] = useApiCall('/offer/complete', {
    request: {
      method: 'POST',
    },
    onError: showApiError,
  });

  const handleAcceptOffer = useCallback(() => {
    if (methodOfEmployment === 'office') setModal('RefuseDigitalProcessModal');
    else if (methodOfEmployment === 'digital') {
      completeOffer({
        body: {
          processingType: 'DIGITAL',
          thirdPartyConsent: isThirdPartyConsentGiven,
        },
      }).then((result) => {
        if (result.status === 'success') {
          actions.setProfileData({
            employment: {
              status: 'OFFER_ACCEPTED',
            },
          });
          actions.setStatusData({
            employmentStatus: 'OFFER_ACCEPTED',
          });

          history.push('/offer-accepted');
        }
      });
    }
  }, [completeOffer, history, isThirdPartyConsentGiven, methodOfEmployment]);

  if (!isReady) return null;

  return (
    <StyledWrapper w="calc(100% - 48px)" maxW="928px" mb="40px">
      {modal === 'RefuseDigitalProcessModal' && (
        <RefuseDigitalProcessModal onCloseModal={() => setModal(null)} thirdPartyConsent={isThirdPartyConsentGiven} />
      )}
      {modal === 'EmploymentDetailsModal' && <EmploymentDetailsModal onCloseModal={() => setModal(null)} />}

      <Box mt="24px" alignItems="center">
        <Box display="inline-block" mr="16px">
          <Text font="Header/H3">Предложение</Text>
        </Box>

        <Box
          w="fit-content"
          background="#1BA049"
          px="8px"
          borderRadius="4px"
          display="inline-block"
          alignItems="center"
        >
          <Text font="Caption/Caption 1" color="Neutral/Neutral 00">
            Принято
          </Text>
        </Box>
      </Box>

      <Box mt="12">
        <Text font="Header/H5" color="Neutral/Neutral 90">
          Подтвердите дату выхода
        </Text>
      </Box>

      <Box mt="16px">
        <Box display="inline-block" w="208px" mr="32px">
          <InputField
            label="Дата выхода на работу"
            value={offer ? formatDate(offer.firstWorkDay) : ''}
            type="text"
            disabled
          />
        </Box>
        <Box display="inline-block" w="288px">
          <InputField label="Должность" value={offer?.jobTitle} type="text" disabled />
        </Box>
      </Box>
      <Box mt="16px">
        <Box w="100%">
          <TextField label="Текст приглашения" value={INVITATION_TEXT} disabled color="red" />
        </Box>
      </Box>

      <Box mt="40px">
        <Text font="Header/H5" color="Neutral/Neutral 90">
          Выберите способ оформления документов о приеме
        </Text>
      </Box>
      <Box
        mt="16px"
        mb="16px"
        p="16px 24px 32px"
        borderRadius="24px"
        backgroundColor={methodOfEmployment === 'digital' ? '#E5F6FF' : '#F3F4F6'}
        style={{
          backgroundBlendMode: 'soft-light',
          backgroundSize: '462px 405px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom 10px right 10px',
          backgroundImage: `url(${keyImg})`,
        }}
      >
        <StyledCheckboxWrapper display="flex" justifyContent="space-between">
          <label>
            <Box display="flex" columnGap="8px" cursor="pointer" mb="8">
              <Box display="flex">
                <RadioButton
                  dimension="m"
                  onChange={() => {
                    setMethodOfEmployment('digital');
                  }}
                  checked={methodOfEmployment === 'digital'}
                />
                <Box>
                  <T font="Body/Body 1 Short">Электронное подписание</T>
                </Box>
              </Box>
            </Box>
          </label>

          <Box>
            <TemplateLink font="Button/Button 1" color="Primary/Primary 60 Main">
              <a href={`${getDocsStorageBaseUrl()}/${getByCode('DIG_SIGN_GUIDE')?.path}`} target="_blank">
                {getByCode('DIG_SIGN_GUIDE')?.title}
              </a>
            </TemplateLink>
          </Box>
        </StyledCheckboxWrapper>

        <Box mt="24px">
          <DigitalSignatureAgitation />
        </Box>

        <Box mt="24px">
          <label>
            <Box mt="16px" display="flex" columnGap="8px" cursor="pointer">
              <Box w="16px">
                <Checkbox
                  dimension="s"
                  checked={isThirdPartyConsentGiven}
                  onChange={() => setIsThirdPartyConsentGiven((i) => !i)}
                />
              </Box>
              <Box mt="-2px" maxWidth="70%">
                <Text font="Body/Body 2 Long" as="div">
                  Я проинформирован об осуществлении электронного документооборота в Банке ВТБ и соглашаюсь на{' '}
                  <Text font="Body/Body 2 Long" color="Primary/Primary 60 Main">
                    <a
                      href={`${getDocsStorageBaseUrl()}/${getByCode('PERS_DATA_TRANSFER_GK')?.path}`}
                      target="_blank"
                    >
                      {getByCode('PERS_DATA_TRANSFER_GK')?.title}
                    </a>
                  </Text>
                </Text>
                <Text font="Caption/Caption 1" color="Neutral/Neutral 90">
                  Обязательно для электронного подписания
                </Text>
              </Box>
            </Box>
          </label>
        </Box>
      </Box>

      <Box borderRadius="24px" backgroundColor={methodOfEmployment === 'office' ? '#E5F6FF' : '#F3F4F6'} p="16px 24px">
        <StyledCheckboxWrapper display="flex" justifyContent="space-between">
          <label>
            <Box display="flex" cursor="pointer" mb="8">
              <RadioButton
                dimension="m"
                onChange={() => {
                  setMethodOfEmployment('office');
                }}
                checked={methodOfEmployment === 'office'}
              />
              <Box>
                <T font="Body/Body 1 Short">Подписание на бумаге</T>
              </Box>
            </Box>
          </label>

          <Box>
            <TemplateLink
              font="Button/Button 1"
              color="Primary/Primary 60 Main"
              onClick={() => setModal('EmploymentDetailsModal')}
            >
              Как проходит процесс оформления?
            </TemplateLink>
          </Box>
        </StyledCheckboxWrapper>
      </Box>

      <Box mt="28px">
        <Button
          disabled={
            completeOfferState.status === 'awaiting' ||
            !methodOfEmployment ||
            (methodOfEmployment === 'digital' && !isThirdPartyConsentGiven)
          }
          appearance="secondary"
          dimension="m"
          onClick={handleAcceptOffer}
        >
          Сохранить выбор
        </Button>
      </Box>
    </StyledWrapper>
  );
});
