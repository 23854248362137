import React from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';

const ImageWrapper = styled(Box)<{ displayOnMobile: boolean }>`
  padding-left: 32px;
  padding-right: 32px;
  @media (max-width: 1024px) {
    width: auto;
    height: auto;
  }

  @media (max-width: 768px) {
    display: ${(props) => (props.displayOnMobile ? 'block' : 'none')};
    width: calc(100vw - 32px);
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: calc(min(420px, 40vw));
  height: auto;

  @media (max-width: 768px) {
    background-position: center center;
    background-repeat: no-repeat;

    width: calc(100vw - 32px);
    height: 200px;
    border-radius: 24px;
  }
`;

export const WelcomeImage = React.memo(
  ({ src, displayOnMobile = false }: { src: string; displayOnMobile?: boolean }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <ImageWrapper displayOnMobile={displayOnMobile}>
          <Image src={src} />
        </ImageWrapper>
      </Box>
    );
  },
);
