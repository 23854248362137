import React from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { T, Spinner } from '@admiral-ds/react-ui';

import { useProfile } from '#user/useProfile';

import { VerifyEmailForm } from './VerifyEmailForm';
import { useStatus } from '#user/useStatus';

const ProfileHeader = () => {
  return (
    <Box mt="24px">
      <Text font="Header/H3">Профиль</Text>
    </Box>
  );
};

const Text = styled(T)`
  margin: 0;
`;

const Loading = () => (
  <Box w="928px" mb="40px" ml="56px">
    <ProfileHeader />
    <Box mt="32px" display="flex" justifyContent="center">
      <Spinner dimension="l" />
    </Box>
  </Box>
);

const VerifyEmailWrapper = styled(Box)`
  width: 428px;
  margin-left: 56px;

  @media (max-width: 639px) {
    width: 100%;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const VerifyEmail = React.memo(() => {
  const { isReady } = useStatus();
  const { profile } = useProfile();

  if (profile.isLoading || !profile.data || !isReady) {
    return <Loading />;
  }

  return (
    <VerifyEmailWrapper mb="40px">
      <ProfileHeader />
      <Box mt="24px">
        <Text font="Body/Body 1 Short" color="Neutral/Neutral 90">
          В Ваш личный кабинет кандидата поступил запрос на смену email.
        </Text>
      </Box>
      <Box mt="24px">
        <Text font="Body/Body 1 Short" color="Neutral/Neutral 90">
          Для возобновления доступа к Вашему личному кабинету, просим ввести email, который Вы использовали при
          первичной регистрации.
        </Text>
      </Box>
      <Box mt="24px">
        <Text font="Body/Body 1 Short" color="Neutral/Neutral 90">
          Если Вы не подавали запрос, свяжитесь с рекрутером.
        </Text>
      </Box>
      <Box mt="32px">
        <VerifyEmailForm />
      </Box>
    </VerifyEmailWrapper>
  );
});
