import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { T } from '@admiral-ds/react-ui';

import { useField } from '#Entry/useField';
import { emailValidators } from '#Entry/validations';

import sideImg from '#assets/side.png';
import { FormType, forms } from './Forms';
import { Box } from '@welcome-ui/box';
import { useApiCall } from '#api/useApiCall';
import { NeedHelp } from './NeedHelp';
import { useProfile } from '#user/useProfile';
import { showNotDetailedApiError } from '#utils/notifications';
import { WelcomeImage } from '#components/WelcomeImage';

import './Entry.css';
import { load } from '#utils/storage';

const RootContainer = styled.div`
  display: grid;

  @media (min-width: 769px) {
    grid-template-columns: auto auto;
  }

  @media (max-width: 768px) {
    grid-template-rows: auto auto;
    gap: 1em;

    & > [data-element='right-panel'] {
      display: flex;
      justify-content: center;
    }
  }
`;

const CustomParagraph = styled(T)`
  margin: 0;
`;

export const Entry = React.memo(
  ({ mode, otp = '', email = load('email:login') ?? '' }: { otp?: string; mode?: 'otp'; email?: string }) => {
    const [emailForOtp, setEmailForOtp] = useState('');
    const [currentForm, setCurrentForm] = useState<FormType>(mode === 'otp' ? 'LoginForm' : 'RequestOTPForm');
    // const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

    const { actions } = useProfile({ autoFetch: false });

    const Form = useMemo(() => forms[currentForm], [currentForm]);

    const emailField = useField({
      initialValue: email,
      validators: { onChange: [], onValidate: emailValidators },
    });

    const otpField = useField({
      initialValue: otp,
      validators: { onChange: [], onBlur: [] },
    });

    const fields = useMemo(
      () => ({
        email: emailField,
        password: otpField,
      }),
      [emailField, otpField],
    );

    const [isFreeze, setIsFreeze] = useState(false);

    const [requestOtpState, requestOtp] = useApiCall('/auth/request-otp', {
      errorFields: fields,
      request: {
        method: 'POST',
      },
      onError: showNotDetailedApiError,
      onFreezeStart() {
        setIsFreeze(true);
      },
      onFreezeEnd() {
        setIsFreeze(false);
      },
    });

    const [loginState, login] = useApiCall('/auth/login', {
      errorFields: fields,
      request: {
        method: 'POST',
      },
      onError: showNotDetailedApiError,
      onFreezeStart() {
        console.log('start freeze!');
        setIsFreeze(true);
      },
      onFreezeEnd() {
        setIsFreeze(false);
      },
    });

    const isSubmitDisabled = isFreeze || requestOtpState.status === 'awaiting' || loginState.status === 'awaiting';

    const onSubmitEmail: React.FormEventHandler<HTMLFormElement> = useCallback(
      async (e) => {
        if (e && typeof e.preventDefault === 'function') e.preventDefault();

        const validationErrors = emailField.validate();

        if (validationErrors.length) return;

        const requestResult = await requestOtp({ body: { email: emailField.value } });

        if (requestResult.status === 'failure') {
          setEmailForOtp('');
        } else if (requestResult.status === 'success') {
          setEmailForOtp(emailField.value);
          setCurrentForm('LoginForm');
        }
      },
      [emailField, requestOtp],
    );

    const onLogin = useCallback(async () => {
      const { isSubmittable } = otpField;

      if (!isSubmittable) return;

      if (emailForOtp !== emailField.value) {
        console.warn('login emailForOtp !== emailField.value', emailForOtp, emailField.value);
      }

      const response = await login({ body: { email: emailField.value, password: otpField.value } });

      if (response.status === 'success') {
        actions.login(emailField.value);
      }
    }, [actions, emailField.value, emailForOtp, login, otpField]);

    return (
      <>
        <RootContainer>
          <WelcomeImage src={sideImg} displayOnMobile />
          <Box data-element="right-panel" display="flex" padding="12px" mt="12px">
            <Box
              maxW="408px"
              textAlign="left"
              display="flex"
              justifyContent="center"
              flexDirection="column"
              padding="12px"
            >
              <CustomParagraph as="h1" font="Header/H2" color="Neutral/Neutral 90">
                Личный кабинет
                <br />
                кандидата
              </CustomParagraph>
              <Box mt="40">
                <CustomParagraph as="h2" font="Header/H4" color="Neutral/Neutral 90">
                  Войдите в свой кабинет
                </CustomParagraph>
              </Box>
              <Form
                isLoading={isSubmitDisabled}
                isSubmitDisabled={isSubmitDisabled}
                otpField={otpField}
                emailField={emailField}
                onSubmitEmail={onSubmitEmail}
                onLogin={onLogin}
              />
              <Box pt="16px" display="flex" justifyContent="center" alignItems="center">
                <NeedHelp />
              </Box>
            </Box>
          </Box>
        </RootContainer>
      </>
    );
  },
);
