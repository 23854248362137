import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { T, Avatar, IconButton } from '@admiral-ds/react-ui';

import leaveImg from '#assets/leave.svg';
import { useProfile } from '#user/useProfile';

import { MainMenuButton } from '../MainMenuButton';

const HeaderWrapper = styled(Box)`
  align-items: center;

  @media (max-width: 1024px) {
    padding: 8px 24px;
  }
  @media (max-width: 639px) {
    flex-direction: column;
    height: auto;
    align-items: flex-start;

    & > [data-element='user'] {
      align-self: flex-end;
    }
  }
`;

const Text = styled(T)`
  margin: 0;
`;

const LogoutButton = styled(IconButton)`
  padding: 0;
`;

export const Header = React.memo(
  React.forwardRef(
    (props: { onMenuClick?(): void; isMenuOpen: boolean }, ref: React.ForwardedRef<HTMLButtonElement>) => {
      const { onMenuClick } = props;
      const { selectors, actions } = useProfile({ autoFetch: true });

      const username = selectors.getDisplayName();
      const handleLogoutButtonClick = useCallback(() => actions.logout(), [actions]);

      return (
        <HeaderWrapper
          w="100%"
          py="8px"
          px="56px"
          display="flex"
          boxSizing="border-box"
          justifyContent="space-between"
          alignItems="center"
          background="#F3F4F6"
        >
          <Box display="flex" alignItems="center">
            <Box mr="8px">
              <MainMenuButton ref={ref} onClick={onMenuClick} />
            </Box>
            <Text color="Neutral/Neutral 90" font="Header/H6">
              Личный кабинет кандидата
            </Text>
          </Box>
          <Box data-element="user" display="flex" alignItems="center" columnGap="16px">
            {!!username && (
              <>
                <Box display="flex" alignItems="center" columnGap="12px" pointerEvents="none">
                  <>
                    <Avatar userName={username} dimension="m" />
                    <Text font="Body/Body 2 Short" color="Neutral/Neutral 90">
                      {username}
                    </Text>
                  </>
                </Box>
                <LogoutButton dimension="m" onClick={handleLogoutButtonClick}>
                  <img style={{ display: 'block' }} src={leaveImg} />
                </LogoutButton>
              </>
            )}
          </Box>
        </HeaderWrapper>
      );
    },
  ),
);
