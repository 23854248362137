const uiState = {
  isGlobalFreeze: false,
};

export const getIsGlobalFreeze = () => uiState.isGlobalFreeze;

export const setIsGlobalFreeze = (isFreeze: boolean) => (uiState.isGlobalFreeze = isFreeze);

export async function freezeForTime(timeout: number): Promise<boolean> {
  setIsGlobalFreeze(true);
  return new Promise((resolve) => {
    setTimeout(() => {
      setIsGlobalFreeze(false);
      resolve(false);
    }, timeout);
  });
}
