import React from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { T } from '@admiral-ds/react-ui';
import { getDocsStorageBaseUrl } from '#utils/getApiUrl';
import { useDisplayedLinks } from '#user/useDisplayedLinks';

const Text = styled(T)`
  margin: 0;
`;
const TemplateLink = styled(T)`
  cursor: pointer;
`;

const StyledWrapper = styled(Box)`
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const DocumentsTemplates = React.memo(() => {
  const { getByCode } = useDisplayedLinks({ fetchStrategy: 'no-auto-fetch' });
  return (
    <>
      <StyledWrapper mb={32} display="flex" justifyContent="space-between" gap="32px">
        <Box data-element="left-side" w="100%">
          <Box mt="8px">
            <Text font="Body/Body 1 Long">
              Шаблоны для заполнения необходимо <b>скачать, внести в них данные, подписать и загрузить</b>. Остальные
              документы можно приложить в виде сканов или фотографий.
            </Text>
          </Box>
        </Box>
        <Box data-element="right-side" w="100%">
          <Box w="448px" maxW="100%">
            <Box>
              <Box mt="8px">
                <TemplateLink font="Button/Button 1" color="Primary/Primary 60 Main">
                  <a href={`${getDocsStorageBaseUrl()}/${getByCode('DOC_UPLOAD_GUIDE')?.path}`} target="_blank">
                    {getByCode('DOC_UPLOAD_GUIDE')?.title}
                  </a>
                </TemplateLink>
              </Box>
              <Box mt="8px">
                <TemplateLink font="Button/Button 1" color="Primary/Primary 60 Main">
                  <a href={`${getDocsStorageBaseUrl()}/${getByCode('FORM_FILL_GUIDE')?.path}`} target="_blank">
                    {getByCode('FORM_FILL_GUIDE')?.title}
                  </a>
                </TemplateLink>
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledWrapper>
      <StyledWrapper>
        <Box data-element="left-side" w="100%">
          <Box mb={8}>
            <Text font="Header/H5">Шаблоны для заполнения</Text>
          </Box>
          <Box>
            <TemplateLink font="Button/Button 1" color="Primary/Primary 60 Main">
              <a href={`${getDocsStorageBaseUrl()}/${getByCode('QUESTIONNAIRE')?.path}`} target="_blank">
                {getByCode('QUESTIONNAIRE')?.title}
              </a>
            </TemplateLink>
          </Box>
          <Box mt="8px">
            <TemplateLink font="Button/Button 1" color="Primary/Primary 60 Main">
              <a href={`${getDocsStorageBaseUrl()}/${getByCode('PERS_DATA_PROCESSING')?.path}`} target="_blank">
                {getByCode('PERS_DATA_PROCESSING')?.title}
              </a>
            </TemplateLink>
          </Box>
          <Box mt="8px">
            <TemplateLink font="Button/Button 1" color="Primary/Primary 60 Main">
              <a href={`${getDocsStorageBaseUrl()}/${getByCode('BKI_CONSENT')?.path}`} target="_blank">
                {getByCode('BKI_CONSENT')?.title}
              </a>
            </TemplateLink>
          </Box>
        </Box>
      </StyledWrapper>
    </>
  );
});
