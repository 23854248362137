import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@admiral-ds/react-ui';
import { Box } from '@welcome-ui/box';

import { TextField } from '#pages/Entry/components/TextField';
import { useField } from '#pages/Entry/useField';
import { ValidationResultError } from '#pages/Entry/validations';
import { emailValidators } from '#Entry/validations';

import { useApiCall } from '#api/useApiCall';
import { CabinetStatus } from '#api/dtos';
import { showNotDetailedApiError } from '#utils/notifications';
import { useStatus } from '#user/useStatus';

export function VerifyEmailForm() {
  const { actions } = useStatus({ autoFetch: false });

  const history = useHistory();
  const emailField = useField({
    initialValue: '',
    validators: { onChange: [], onValidate: emailValidators },
  });

  const fields = useMemo(
    () => ({
      email: emailField,
    }),
    [emailField],
  );

  const verifyEmailPayload = useMemo(() => {
    return {
      email: emailField.value,
    };
  }, [emailField.value]);

  const [verifyEmailState, verifyEmail] = useApiCall<CabinetStatus>('/verify', {
    errorFields: fields,
    request: {
      method: 'POST',
    },
    onError: showNotDetailedApiError,
  });

  type Field<T = unknown> = ReturnType<typeof useField<T>>;

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      const fieldsValidators: Array<() => ValidationResultError[]> = (
        Object.keys(fields) as unknown as Array<keyof typeof fields>
      ).map((fieldName) => (fields[fieldName] as Field).validate);

      const errors = fieldsValidators.reduce((acc: Array<ValidationResultError>, fv) => {
        return [...acc, ...fv()];
      }, []);

      if (errors.length) {
        console.warn('fix validation errors', errors);
        return;
      }

      const response = await verifyEmail({ body: verifyEmailPayload });
      if (response.status === 'success' && response.data?.profileStatus !== 'MUST_VERIFY') {
        actions.setStatusData({
          profileStatus: response.data?.profileStatus,
        });
        setTimeout(() => {
          history.push('/profile');
        }, 0);
      } else {
        console.error('Failed to verify email', response);
      }
    },
    [actions, fields, history, verifyEmail, verifyEmailPayload],
  );

  return (
    <form onSubmit={onSubmit}>
      <Box mb="6">
        <TextField
          {...emailField.getProps()}
          label="Email"
          placeholder="mail@mail.ru"
          data-container-id="emailField"
          className="input-field"
          type="text"
        />
      </Box>
      <Box mt="16" mb="40">
        <Button dimension="m" type="submit" disabled={verifyEmailState.status === 'awaiting'}>
          Отправить
        </Button>
      </Box>
    </form>
  );
}
