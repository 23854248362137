import { useEffect, useMemo } from 'react';

import { useUserContext } from '#user/UserProfileContext';
import { FetchStrategyKey } from '#components/Refetcher/Refetcher';
import { DisplayedLink } from '#api/dtos';

export type FileLinkCode =
  | 'DIG_SIGN_GUIDE'
  | 'DOC_UPLOAD_GUIDE'
  | 'FORM_FILL_GUIDE'
  | 'USER_AGREEMENT'
  | 'PERS_DATA_TRANSFER'
  | 'VACANCY_CONSENT'
  | 'RESERVE_CONSENT'
  | 'BKI_CONSENT'
  | 'PERS_DATA_PROCESSING'
  | 'PERS_DATA_TRANSFER_GK'
  | 'QUESTIONNAIRE'
  | 'DOCS_LIST'
  | 'PERS_DATA_ZP'

export type FilesLinksResponseBody = DisplayedLink[];

export interface UseStatusProps {
  fetchStrategy?: FetchStrategyKey;
}

export function useDisplayedLinks(props?: UseStatusProps) {
  const { store, actions, selectors } = useUserContext();
  const { fetchStrategy = 'no-auto-fetch' } = props || {};

  const dataStatus = store.displayedLinks.status;

  useEffect(() => {
    if (fetchStrategy === 'refetch-on-mount') {
      actions.loadDisplayedLinks();
    }
  }, []);

  useEffect(() => {
    if (fetchStrategy === 'no-auto-fetch') {
      return;
    }
    if (fetchStrategy === 'fetch-initial') {
      if (dataStatus === 'initial') {
        actions.loadDisplayedLinks();
      }
    }
  }, [actions, dataStatus, fetchStrategy]);

  const result = useMemo(
    () => ({
      getByCode(code: FileLinkCode) {
        return store.displayedLinks.data?.[code];
      },
      isInitial: dataStatus === 'initial',
      isLoading: dataStatus === 'awaiting',
      isReady: dataStatus === 'success',
      isError: dataStatus === 'failure',
      error: store.displayedLinks.error,
      links: store.displayedLinks.data,

      store,
      actions,
      selectors,
    }),
    [actions, dataStatus, selectors, store],
  );

  return result;
}

export type UseDisplayedLinksHook = ReturnType<typeof useDisplayedLinks>;
