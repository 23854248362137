import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { T, Button, CheckboxField, Link } from '@admiral-ds/react-ui';
import { Box } from '@welcome-ui/box';
import styled from 'styled-components';

import { TextField } from '#pages/Entry/components/TextField';
import { useField } from '#pages/Entry/useField';
import { ValidationResultError } from '#pages/Entry/validations';

import { useApiCall } from '#api/useApiCall';
import { getDocsStorageBaseUrl } from '#utils/getApiUrl';
import { showNotDetailedApiError } from '#utils/notifications';
import { makeUpdateCandidatePayload } from '#user/editCandidate';
import { enqueueSnackbar } from 'notistack';
import { useDisplayedLinks } from '#user/useDisplayedLinks';
import { useStatus } from '#user/useStatus';
import { save } from '#utils/storage';

const Text = styled(T)`
  margin: 0;
`;

function required<T>(errorText = 'Поле обязательное') {
  return (v: T) => (!v ? errorText : null);
}

export interface RegisterFormProps {
  email: string;
}

export function RegisterForm({ email }: RegisterFormProps) {
  const history = useHistory();

  const { actions } = useStatus();

  const links = useDisplayedLinks({ fetchStrategy: 'no-auto-fetch' });

  const agreeLKKField = useField<boolean>({
    initialValue: false,
    validators: {
      onBlur: [],
      onChange: [],
      onValidate: [required()],
    },
  });

  const agreeVacancyField = useField<boolean>({
    initialValue: false,
    errorHeight: 0,
    validators: {
      onBlur: [],
      onChange: [],
      onValidate: [required()],
    },
  });

  const agreeReserveField = useField<boolean>({
    initialValue: false,
    errorHeight: 0,
    validators: {
      onBlur: [],
      onChange: [],
      onValidate: [],
    },
  });

  const agreePersonalDataField = useField<boolean>({
    initialValue: false,
    errorHeight: 0,
    validators: {
      onBlur: [],
      onChange: [],
      onValidate: [required()],
    },
  });

  const nameField = useField({
    initialValue: '',
    errorHeight: 26,
    validators: { onChange: [], onValidate: [required('Введите имя')] },
  });

  const surnameField = useField({
    initialValue: '',
    errorHeight: 26,
    validators: { onChange: [], onValidate: [required('Введите фамилию')] },
  });

  const noPatronymicField = useField<boolean>({
    initialValue: false,
    validators: {
      onBlur: [],
      onChange: [],
      onValidate: [],
    },
  });

  const patronymicField = useField({
    initialValue: '',
    errorHeight: 25,
    validators: {
      onChange: [],
      onValidate: [noPatronymicField.getCheckboxProps().checked ? () => null : required('Введите отчество')],
    },
  });

  const fields = useMemo(
    () => ({
      firstName: nameField,
      middleName: patronymicField,
      lastName: surnameField,
      noMiddleName: noPatronymicField,
      vacancyConsent: agreeVacancyField,
      reserveConsent: agreeReserveField,
      userAgreement: agreeLKKField,
      personalDataTransfer: agreePersonalDataField,
    }),
    [
      agreeLKKField,
      agreePersonalDataField,
      agreeReserveField,
      agreeVacancyField,
      nameField,
      noPatronymicField,
      patronymicField,
      surnameField,
    ],
  );

  const [registerState, register] = useApiCall('/register', {
    errorFields: fields,
    request: {
      method: 'POST',
    },
    onError: showNotDetailedApiError,
  });

  const isLoading = registerState.status === 'awaiting';

  useEffect(() => {
    if (noPatronymicField.value && patronymicField.value !== '') {
      patronymicField.onChange('');
    }
  }, [noPatronymicField.value, patronymicField]);

  type Field<T = unknown> = ReturnType<typeof useField<T>>;

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      const fieldsValidators: Array<() => ValidationResultError[]> = (
        Object.keys(fields) as unknown as Array<keyof typeof fields>
      ).map((fieldName) => (fields[fieldName] as Field).validate);

      const errors = fieldsValidators.reduce((acc: Array<ValidationResultError>, fv) => {
        return [...acc, ...fv()];
      }, []);

      if (errors.length) {
        const displayError = errors.find((e) => e.error === 'Поле обязательное');
        if (displayError) {
          enqueueSnackbar({
            variant: 'error',
            message: <div>Примите все обязательные соглашения</div>,
          });
        }
        return;
      }

      const response = await register({
        body: makeUpdateCandidatePayload({
          email,
          firstName: nameField.value,
          middleName: patronymicField.value,
          lastName: surnameField.value,
          noMiddleName: noPatronymicField.value,
          vacancyConsent: agreeVacancyField.value,
          reserveConsent: agreeReserveField.value,
          userAgreement: agreeLKKField.value,
          personalDataTransfer: agreePersonalDataField.value,
        }),
      });
      if (response.status === 'success') {
        actions.setStatusData({
          profileStatus: 'REGISTERED',
        });
        actions.setProfileData({
          status: 'REGISTERED',
        });
        // TODO: push to page determined by employment status
        save('showModal', 'WelcomeModal');
        history.push('/profile?welcome=true');
      } else {
        console.error('Failed to register', response);
      }
    },
    [
      actions,
      agreeLKKField.value,
      agreePersonalDataField.value,
      agreeReserveField.value,
      agreeVacancyField.value,
      email,
      fields,
      history,
      nameField.value,
      noPatronymicField.value,
      patronymicField.value,
      register,
      surnameField.value,
    ],
  );

  const vacancyLink = links.links?.['VACANCY_CONSENT'];
  const reserveLink = links.links?.['RESERVE_CONSENT'];
  const pdtLink = links.links?.['PERS_DATA_TRANSFER'];
  const userAgreementLink = links.links?.['USER_AGREEMENT'];

  return (
    <form onSubmit={onSubmit}>
      <Box mb="6">
        <TextField
          {...surnameField.getProps()}
          label="Фамилия"
          placeholder="Иванов"
          ata-container-id="textFieldIdTwo"
          className="input-field"
          disabled={isLoading}
          type="text"
        />
      </Box>

      <Box mb="6">
        <TextField
          {...nameField.getProps()}
          label="Имя"
          placeholder="Иван"
          ata-container-id="textFieldIdTwo"
          className="input-field"
          disabled={isLoading}
          type="text"
        />
      </Box>

      <Box>
        <TextField
          {...patronymicField.getProps()}
          label="Отчество"
          placeholder="Иванович"
          ata-container-id="textFieldIdTwo"
          className="input-field"
          disabled={isLoading || noPatronymicField.getCheckboxProps().checked}
          type="text"
        />
      </Box>

      <Box mb="12">
        <CheckboxField
          {...noPatronymicField.getCheckboxProps()}
          data-container-id="checkboxFieldIdOne"
          id="checkboxFieldId"
          name="checkboxFieldName"
          disabled={isLoading}
        >
          <Text font="Body/Body 1 Short" as="div">
            Нет отчества
          </Text>
        </CheckboxField>
      </Box>

      <Box mb="24" />

      <Box mb="12" display="flex" justifyContent="center">
        <T font="Header/H5" as="div">
          Для регистрации личного кабинета кандидата необходимо принять документы
        </T>
      </Box>

      <Box mb="12">
        <CheckboxField
          {...agreeVacancyField.getCheckboxProps()}
          data-container-id="checkboxFieldIdOne"
          id="checkboxFieldId"
          name="checkboxFieldName"
          disabled={isLoading}
        >
          <Link to="/doc1">
            <Text font="Body/Body 1 Short" color="Primary/Primary 60 Main">
              <a href={`${getDocsStorageBaseUrl()}/${vacancyLink?.path}`} target="_blank">
                {vacancyLink?.title}*
              </a>
            </Text>
          </Link>
        </CheckboxField>
      </Box>
      <Box mb="12">
        <CheckboxField
          {...agreeReserveField.getCheckboxProps()}
          data-container-id="checkboxFieldIdOne"
          id="checkboxFieldId"
          name="checkboxFieldName"
          disabled={isLoading}
        >
          <Link to="/doc1">
            <Text font="Body/Body 1 Short" color="Primary/Primary 60 Main">
              <a href={`${getDocsStorageBaseUrl()}/${reserveLink?.path}`} target="_blank">
                {reserveLink?.title}
              </a>
            </Text>
          </Link>
        </CheckboxField>
      </Box>
      <Box mb="12">
        <CheckboxField
          {...agreeLKKField.getCheckboxProps()}
          data-container-id="checkboxFieldIdOne"
          id="checkboxFieldId"
          name="checkboxFieldName"
          disabled={isLoading}
        >
          <Link to="/doc1">
            <Text font="Body/Body 1 Short" color="Primary/Primary 60 Main">
              <a href={`${getDocsStorageBaseUrl()}/${userAgreementLink?.path}`} target="_blank">
                {userAgreementLink?.title}*
              </a>
            </Text>
          </Link>
        </CheckboxField>
      </Box>
      <Box mb="6">
        <CheckboxField
          {...agreePersonalDataField.getCheckboxProps()}
          data-container-id="checkboxFieldIdOne"
          id="checkboxFieldId"
          name="checkboxFieldName"
          disabled={isLoading}
        >
          <Link to="/doc1">
            <Text font="Body/Body 1 Short" color="Primary/Primary 60 Main">
              <a href={`${getDocsStorageBaseUrl()}/${pdtLink?.path}`} target="_blank">
                {pdtLink?.title}*
              </a>
            </Text>
          </Link>
        </CheckboxField>
      </Box>

      <Box mb="32">
        <Text font="Caption/Caption 1">*обязательно для регистрации</Text>
      </Box>

      <Box mt="16">
        <Button dimension="m" style={{ width: '100%' }} type="submit" disabled={isLoading}>
          Зарегистрироваться
        </Button>
      </Box>
    </form>
  );
}
