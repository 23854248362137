import { IDocumentUi, IDocumentType, IDocumentsByType } from './models';

export function getNumberPostfix(number: number): string {
  switch (number) {
    case 1:
      return '-го';
    case 2:
      return '-х';
    case 3:
      return '-х';
    case 4:
      return '-х';
    case 5:
      return '-и';
    case 6:
      return '-и';
    case 7:
      return '-и';
    case 8:
      return '-и';
    case 9:
      return '-и';
    case 0:
      return '-и';
  }

  const stringifiedNumber = String(number);
  return getNumberPostfix(+stringifiedNumber[stringifiedNumber.length - 1]);
}

export const formatFileType = (type: string) => {
  switch (type) {
    case 'image/jpeg':
      return 'JPEG';
    case 'image/png':
    case 'image/apng':
      return 'PNG';
    case 'image/tiff':
      return 'TIFF';
    case 'image/bmp':
    case 'image/x-bitmap':
      return 'BMP';
    case 'image/gif':
      return 'GIF';
    case 'application/pdf':
      return 'PDF';
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'PPT';
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'XLS';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'Word';
    case 'application/rtf':
      return 'RTF';
    case 'text/plain':
      return 'TXT';
    case 'application/vnd.oasis.opendocument.text':
      return 'ODT';
    case 'application/vnd.oasis.opendocument.presentation':
      return 'ODP';
    case 'application/vnd.oasis.opendocument.spreadsheet':
      return 'ODS';
    default:
      return 'Docs';
  }
};

export function getNotApplicableText(euin: string): string {
  switch (euin) {
    case 'estf005':
      return 'Ранее не выдавалась';
    case 'estf008':
      return 'Я не состою на воинском учете';
    default:
      return 'Не применимо в моём случае';
  }
}

export function getMaxPossibleAmountOfFilesToUpload(documentType: IDocumentType, documents: IDocumentUi[]): number {
  const notSubmittedDocuments = documents.filter((d) => d.docSetStatus === 'NEW');
  const documentsWithEuinNeeded = notSubmittedDocuments.filter((d) => d.euin === documentType.euin);

  const currentUnuploadedAmount = documentsWithEuinNeeded.filter((d) => d.uiStatus === 'Loading').length;
  const currentUploadedAmount = documentsWithEuinNeeded.filter((d) => d.uiStatus === 'Uploaded').length;

  return documentType.maxFiles - currentUnuploadedAmount - currentUploadedAmount;
}

export function checkIfAllRequiredDocumentsAreAttached(
  documentTypes: IDocumentType[],
  documents: IDocumentUi[],
  notApplicableDocumentTypes: string[],
) {
  if (documents.length === 0 || documentTypes.length === 0) return false;

  let result = true;
  documentTypes.forEach((documentType) => {
    if (documentType.mayBeNotApplicable && notApplicableDocumentTypes.includes(documentType.euin)) {
      return;
    }
    const documentsOfType = documents.filter((d) => d.euin === documentType.euin);
    if (documentsOfType.length < documentType.minFiles) {
      result = false;
    }
  });

  return result;
}

export function checkIfFileSizeIsValid(sizeInBytes: number) {
  const allowedSizeInMegabytes = 5;
  const allowedSizeInBytes = 1024 * 1024 * allowedSizeInMegabytes;
  return allowedSizeInBytes >= sizeInBytes;
}

export function checkIfFileExtensionIsValid(filename: string, allowedFileExtensions: string[]) {
  const fileExtension = filename.split('.').pop();
  if (!fileExtension) return false;

  // В этом месте предполагается, что список допустимых расширений в нижнем регистре
  return (allowedFileExtensions).includes(fileExtension.toLowerCase());
}

export function convertDocumentsDtoStructureToArray(data: IDocumentsByType[]): IDocumentUi[] {
  const result: IDocumentUi[] = [];

  (data || []).forEach((documentByType) => {
    (documentByType.docSets || []).forEach((docSet) => {
      (docSet.documents || []).forEach((document) => {
        result.push({
          ...document,
          // На этапе MVP на уже загруженном файле не может быть ошибок
          uiStatus: 'Uploaded',
          docSet: docSet.docSet,
          docSetStatus: docSet.docSetStatus,
          submitDate: docSet.submitDate,
        });
      });
    });
  });

  return result;
}
