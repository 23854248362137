import styled from 'styled-components';

export const Link = styled('a')`
  cursor: pointer;
  display: flex;
  pointer-events: auto;
  -webkit-box-align: center;
  align-items: center;
  width: fit-content;
  text-decoration: none;
  position: relative;
  border-radius: 4px;
  color: rgb(0, 98, 255);
  font-family: 'VTB Group UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  font-feature-settings: 'tnum', 'lnum';
  text-rendering: geometricprecision;
`;
