import { useCallback } from 'react';
import { T, Button, Link } from '@admiral-ds/react-ui';
import { Box } from '@welcome-ui/box';

import { TextField } from '#pages/Entry/components/TextField';
import { Countdown } from '../../Countdown';
import { FormProps } from '../forms';
import { useCountdown } from '../../Countdown/useCountdown';

export interface LoginFormProps extends FormProps {
  showCountdown?: boolean;
}
export function LoginForm(props: LoginFormProps) {
  const { timeLeft, reset } = useCountdown({ from: 60, to: 0, step: 1 });
  const { emailField, otpField, onLogin, onSubmitEmail, showCountdown, isSubmitDisabled, isLoading } = props;

  const isOtpFieldError = !!otpField.errorText;

  const onSubmitLogin = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      onLogin();
    },
    [onLogin],
  );

  const onRequestOtpAgain = useCallback(() => {
    reset();
    onSubmitEmail();
  }, [reset, onSubmitEmail]);

  return (
    <form onSubmit={onSubmitLogin}>
      <Box mb="24">
        <TextField
          {...emailField.getProps()}
          type="email"
          autoComplete="email"
          name="email"
          placeholder="mail@mail.com"
          // required
          ata-container-id="textFieldIdTwo"
          className="input-field"
          disabled={false}
          label={'email'}
        />
      </Box>
      <Box>
        <TextField
          autoFocus
          {...otpField}
          name="otp"
          placeholder="123456"
          // required
          ata-container-id="textFieldIdTwo"
          className="input-field"
          disabled={isLoading}
          label={'Введите код'}
        />
        {showCountdown && <Countdown from={60} />}
      </Box>
      <Box mb="16">
        {!isOtpFieldError && (
          <T font="Body/Body 2 Short" color="Neutral/Neutral 50">
            На email отправлен одноразовый код для входа
          </T>
        )}
      </Box>
      <Box mb="32" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <T font="Body/Body 2 Short" color="Neutral/Neutral 90">
          {timeLeft ? `Отправить новый код через: ${timeLeft} сек` : ''}
        </T>
        <Box mt="32px">
          <Link disabled={!!timeLeft} style={{ display: 'inline-block' }} onClick={onRequestOtpAgain}>
            Получить код повторно
          </Link>
        </Box>
      </Box>
      <Box mb="20">
        <Button
          style={{ width: '100%' }}
          type="submit"
          disabled={isSubmitDisabled || !otpField.value || !otpField.isSubmittable}
          loading={isLoading}
        >
          Войти
        </Button>
      </Box>
    </form>
  );
}
