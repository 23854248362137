export type ValidationStatus = string | null;
export type ValidationResultSuccess = { error: null };
export type ValidationResultError = { error: string; errorDetails?: unknown };
export type ValidationResult = ValidationResultSuccess | ValidationResultError;

export interface FieldValidator<T = string> {
  validate(value?: T): ValidationResult;
}

export type ValidationTestFn<T = string> = (value: T) => ValidationStatus;

export function makeValidator<T>(fn: ValidationTestFn<T> /* _context?: unknown */) {
  return { validate: (value: T) => ({ error: fn(value) }) };
}

export const EMAIL_REGEX_RFC_5322 =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const EMAIL_SIMPLE = /^[а-яА-ЯёЁa-zA-Z0-9_.+-]+@[а-яА-ЯёЁa-zA-Z0-9-]+\.[а-яА-ЯёЁa-zA-Z0-9-.]+$/

export const notEmpty = (value: string) => {
  const isOk = !!value.length;

  if (isOk) return null;

  return 'Укажите почту, которую указывали в резюме';
};

export const testEmail = (value: string) => {
  const isOk = EMAIL_SIMPLE.test(value);

  if (isOk) return null;

  return 'Некорректная почта. Проверьте правильность написания';
};

export const emailValidators = [testEmail, notEmpty];
