import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { T, Button, InputField, TextField } from '@admiral-ds/react-ui';

import { RejectOfferModal } from './components/RejectOfferModal';
import { useOffer } from '#user/useOffer';
import { useApiCall } from '#api/useApiCall';
import { showApiError } from '#utils/notifications';
import { useHistory } from 'react-router-dom';
import { formatDate } from '#utils/date';
import { INVITATION_TEXT } from '../../constants';

const Text = styled(T)`
  margin: 0;
`;

const StyledWrapper = styled(Box)`
  & input[type='text'][disabled]:disabled,
  & textarea[disabled]:disabled {
    color: #23262d;
  }
`;

export const OfferReceived = React.memo(() => {
  const history = useHistory();
  const { offer, isReady, actions } = useOffer();
  const [modal, setModal] = useState<'RejectModal' | null>(null);

  const [acceptOfferState, acceptOffer] = useApiCall('/offer/accept', {
    request: {
      method: 'POST',
    },
    onError: showApiError,
  });

  const handleAcceptOffer = useCallback(() => {
    acceptOffer().then((result) => {
      if (result.status === 'success') {
        actions.setProfileData({
          employment: {
            status: 'OFFER_ACCEPTED',
          },
        });
        actions.setStatusData({
          employmentStatus: 'OFFER_ACCEPTED',
        });
        history.push('/offer-accepted');
      }
    });
  }, [acceptOffer, actions, history]);

  if (!isReady) return null;

  return (
    <StyledWrapper w="calc(100% - 48px)" maxW="928px" mb="40px">
      {modal === 'RejectModal' && <RejectOfferModal onCloseModal={() => setModal(null)} />}

      <Box mt="24px" alignItems="center">
        <Box display="inline-block" mr="16px">
          <Text font="Header/H3">Предложение</Text>
        </Box>

        <Box
          w="fit-content"
          background="#FF5C22"
          px="8px"
          borderRadius="4px"
          display="inline-block"
          alignItems="center"
        >
          <Text font="Caption/Caption 1" color="Neutral/Neutral 00">
            Ожидает решения
          </Text>
        </Box>
      </Box>

      <Box mt="12">
        <Text font="Header/H5" color="Neutral/Neutral 90">
          Подтвердите дату выхода
        </Text>
      </Box>

      <Box mt="16px">
        <Box display="inline-block" w="208px" mr="32px">
          <InputField
            label="Дата выхода на работу"
            value={offer ? formatDate(offer.firstWorkDay) : ''}
            type="text"
            disabled
          />
        </Box>
        <Box display="inline-block" w="288px">
          <InputField label="Должность" value={offer?.jobTitle} type="text" disabled />
        </Box>
      </Box>
      <Box mt="16px">
        <Box w="100%">
          <TextField label="Текст приглашения" value={INVITATION_TEXT} disabled color="red" />
        </Box>
      </Box>

      <Box display="flex" mt="16px" columnGap="16px">
        <Button
          appearance="secondary"
          dimension="m"
          onClick={handleAcceptOffer}
          disabled={acceptOfferState.status === 'awaiting'}
        >
          Принять
        </Button>
        <Button
          appearance="ghost"
          dimension="m"
          onClick={() => setModal('RejectModal')}
          disabled={acceptOfferState.status === 'awaiting'}
        >
          Отклонить
        </Button>
      </Box>
    </StyledWrapper>
  );
});
