export function extractFilenameFromHeader(header: string): string | null {
  const match = header.match(/filename\*=UTF-8''([^;]+)/);
  if (match && match[1]) {
    const filename = decodeURIComponent(match[1]);
    return filename
  }

  // Check if there is a match and return the filename
  return null;
}

export function saveAs(blob: Blob, fileName: string) {
  const url = URL.createObjectURL(blob);

  const anchorElem = document.createElement('a');
  anchorElem.style.display = 'none';
  anchorElem.href = url;
  anchorElem.download = fileName;

  document.body.appendChild(anchorElem);
  anchorElem.click();

  document.body.removeChild(anchorElem);

  // On Edge, revokeObjectURL should be called only after
  // a.click() has completed, atleast on EdgeHTML 15.15048
  setTimeout(function () {
    window.URL.revokeObjectURL(url);
  }, 1000);
}
