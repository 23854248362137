export const DATE_TIME = 'DD.MM.YYYY HH:mm';

export function formatDate(inputDate: string): string {
  const parts = inputDate.split('-');
  if (parts.length !== 3) {
    return inputDate;
  }

  const date = new Date(`${parts[1]}/${parts[2]}/${parts[0]}`);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}
