import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import {
  Button,
  TextField,
  Modal,
  ModalTitle,
  ModalContent,
  ModalButtonPanel,
  SelectField,
  Option,
} from '@admiral-ds/react-ui';
import { useApiCall, useApiQuery } from '#api/useApiCall';
import { showApiError } from '#utils/notifications';
import { PaperChoiceTypeDictionary } from '#api/dtos';
import { useHistory } from 'react-router-dom';
import { findPaperChoiceByCode } from '#user/useOffer';
import { useStatus } from '#user/useStatus';

export interface RejectOfferFormProps {
  onCloseModal: (reason?: unknown) => void;
  thirdPartyConsent: boolean;
}

const StyledTextField = styled(TextField)``;

const DESCRIPTION_MAX_CHARACTERS = 500;

export const RefuseDigitalProcessModal = ({ onCloseModal, thirdPartyConsent }: RejectOfferFormProps) => {
  const history = useHistory();
  const [refuseDigitalProcessTypesState, loadRefuseDigitalProcessTypes] = useApiQuery<
    PaperChoiceTypeDictionary['items']
  >('/paper-choice-types', {});
  const [, completeOffer] = useApiCall('/offer/complete', {
    request: {
      method: 'POST',
    },
    onError: showApiError,
  });

  const [selectedRejectionType, setSelectedRejectionType] = React.useState<string | undefined>(undefined);
  const [rejectionDescription, setRejectionDescription] = React.useState('');
  const { actions } = useStatus();

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRejectionType(e.target.value);
  };

  const options = useMemo(() => {
    if (!refuseDigitalProcessTypesState.data) return [];

    const list = refuseDigitalProcessTypesState.data;
    if (!Array.isArray(list)) console.warn('Список типов отказа должен быть массивом, но получен', list);

    return list;
  }, [refuseDigitalProcessTypesState.data]);

  // todo: каждый ли rejection type должен сопровождаться полем с описанием
  const isRejectionDescriptionField = !!findPaperChoiceByCode(
    selectedRejectionType,
    refuseDigitalProcessTypesState.data,
  )?.needComment;

  const isRejectionFieldSubmittable = isRejectionDescriptionField ? rejectionDescription.length > 0 : true;

  const onSave = useCallback(async () => {
    if (!isRejectionFieldSubmittable) return;

    const result = await completeOffer({
      body: {
        processingType: 'PAPER',
        reasonCode: selectedRejectionType,
        reasonComment: rejectionDescription,
        thirdPartyConsent,
      },
    });
    if (result.status === 'success') {
      actions.setProfileData({
        employment: {
          status: 'OFFER_ACCEPTED',
        },
      });
      actions.setStatusData({
        employmentStatus: 'OFFER_ACCEPTED',
      });
      history.push('/offer-accepted');
    } else {
      console.log('complete offer call failed', result);
    }
  }, [
    isRejectionFieldSubmittable,
    completeOffer,
    selectedRejectionType,
    rejectionDescription,
    thirdPartyConsent,
    actions,
    history,
  ]);

  // inital settings loading
  useEffect(() => {
    loadRefuseDigitalProcessTypes();
  }, []);

  return (
    <Modal onClose={onCloseModal} style={{ maxWidth: 'calc(100% - 48px)' }}>
      <ModalTitle id="modal-title">Отказ от электронного подписания</ModalTitle>
      <ModalContent style={{ background: '#DDE9FF' }}>
        <SelectField
          label="Причина отказа"
          value={selectedRejectionType}
          onChange={handleSelectChange}
          placeholder={`Укажите причину отказа`}
        >
          {options.map((option) => (
            <Option key={option.code} value={option.code}>
              {option.title}
            </Option>
          ))}
        </SelectField>
        <Box mt="16px">
          {isRejectionDescriptionField && (
            <StyledTextField
              displayCharacterCounter={false}
              maxLength={DESCRIPTION_MAX_CHARACTERS}
              label="Расскажите подробнее"
              placeholder={`Укажите причину отказа (до ${DESCRIPTION_MAX_CHARACTERS} символов)`}
              value={rejectionDescription}
              onChange={(e) => {
                setRejectionDescription(e.target.value);
              }}
              extraText={
                <Box as="span" display="flex" justifyContent={'space-between'} flexDirection="row">
                  <span>Обязательно для заполнения</span>
                  <span>
                    {rejectionDescription.length}/{DESCRIPTION_MAX_CHARACTERS}
                  </span>
                </Box>
              }
            />
          )}
        </Box>
      </ModalContent>
      <ModalButtonPanel>
        <Button
          appearance="primary"
          dimension="m"
          onClick={onSave}
          disabled={!selectedRejectionType || !isRejectionFieldSubmittable}
        >
          Сохранить
        </Button>
        <Button appearance="secondary" dimension="m" onClick={onCloseModal}>
          Закрыть окно
        </Button>
      </ModalButtonPanel>
    </Modal>
  );
};
