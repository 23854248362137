import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { T, Spinner, Button, Checkbox, useToast } from '@admiral-ds/react-ui';
import { isAnyOfferStatus, useStatus } from '#user/useStatus';
import { useDocuments } from './useDocuments';
import { DocumentsTemplates } from './components/DocumentsTemplates';
import { DocumentType } from './components/DocumentType';
import { DocumentTypeToClarify } from './components/DocumentTypeToClarify';
import { DocumentConstraintsHint } from './components/DocumentContraintsHint';
import { SalaryCardIssuingAccept } from './components/SalaryCardIssuingAccept';
import { useProfile } from '#user/useProfile';
import { SalaryCardIssuingAcceptModal } from './components/SalaryCardIssuingAcceptModal';
import { getApiUrl } from '#utils/getApiUrl';
import { v4 as uuidv4 } from 'uuid';
import { FileUploadErrorToast } from './components/FileUploadErrorToast';
import { defaultRequestInit } from '#api/fetcher';

const Text = styled(T)`
  margin: 0;
`;

const StyledFlexColumnForMobiles = styled(Box)`
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Documents = React.memo(() => {
  const { employmentStatus, actions } = useStatus({ autoFetch: false });
  const {
    documentTypes,
    documents,
    fileExtensions,
    isInitalLoading,
    handleDocumentUpload,
    handleDocumentRemove,
    haveDocumentsToSubmitToVtb,
    isRequiredDocumentsAttached,
    notApplicableDocumentTypes,
    handleNonApplicableDocumentTypeToggle,
  } = useDocuments();
  const [isUserConfirmsAccuracy, setIsUserConfirmsAccuracy] = useState(false);
  const [isSalaryCardIssuingAccepted, setIsSalaryCardIssuingAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { profile } = useProfile();
  const history = useHistory();
  const toasts = useToast();

  const handleDocumentsAccurancyCheckboxChange = useCallback(() => {
    setIsUserConfirmsAccuracy((i) => !i);
  }, []);

  const handleSubmit = async () => {
    const response: { data?: { detail: string }; ok: boolean } = await fetch(`${getApiUrl()}/documents/submit`, {
      ...defaultRequestInit,
      method: 'POST',
    });

    if (!response.ok) {
      const defaultErrorMessage = 'Не удалось отправить файлы на согласование';
      const toastId = uuidv4();

      toasts.addToastItem({
        renderToast: () => (
          <FileUploadErrorToast
            text={response.data?.detail || defaultErrorMessage}
            onRemove={() => {
              toasts.removeById(toastId);
            }}
          />
        ),
        id: toastId,
      });
      return;
    }

    if (profile.data && profile.data.candidate.personalDataTransferZP !== isSalaryCardIssuingAccepted) {
      const { candidate, employment } = profile.data;

      await fetch(`${getApiUrl()}/user/profile`, {
        ...defaultRequestInit,
        method: 'PUT',
        body: JSON.stringify({
          employment,
          candidate: { ...candidate, personalDataTransferZP: isSalaryCardIssuingAccepted },
        }),
      });
    }

    actions.setStatusData({
      employmentStatus: 'DOCUMENTS_SENT',
    });
    actions.setProfileData({
      employment: {
        status: 'DOCUMENTS_SENT',
      },
      candidate: { personalDataTransferZP: isSalaryCardIssuingAccepted },
    });
    history.push('/documents-sent');
  };

  const handleClickSubmitButton = () => {
    if (!isSalaryCardIssuingAccepted) {
      setShowModal(true);
    } else {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (!isRequiredDocumentsAttached) setIsUserConfirmsAccuracy(false);
    if (!haveDocumentsToSubmitToVtb) setIsUserConfirmsAccuracy(false);
  }, [haveDocumentsToSubmitToVtb, isRequiredDocumentsAttached]);

  useLayoutEffect(() => {
    profile.data && setIsSalaryCardIssuingAccepted(profile.data.candidate.personalDataTransferZP);
  }, [profile.data]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (isAnyOfferStatus(employmentStatus)) {
    return <Redirect to="/documents-sent" />;
  }

  if (isInitalLoading || profile.isLoading) {
    return (
      <Box w="928px" mb="40px">
        <Box mt="24px">
          <Text font="Header/H3">Документы</Text>
        </Box>
        <Box mt="32px" display="flex" justifyContent="center">
          <Spinner dimension="l" />
        </Box>
      </Box>
    );
  }

  const requiredDocumentTypes = (documentTypes || []).filter((dt) => dt.minFiles > 0);
  const optionalDocumentTypes = (documentTypes || []).filter((dt) => dt.minFiles === 0);
  const isClarificationNeeded = documents.some((d) => d.docSetStatus === 'SUBMITTED');

  return (
    <Box w="calc(100% - 48px)" maxW="928px" mb="40px">
      <Box mt="24px">
        <Text font="Header/H3">Документы</Text>
      </Box>
      <Box mt="16px" pb="32px">
        <DocumentsTemplates />
      </Box>
      <>
        {requiredDocumentTypes.length ? (
          <StyledFlexColumnForMobiles display="flex" justifyContent="space-between">
            <Text font="Header/H4">Обязательные к загрузке</Text>
            <DocumentConstraintsHint fileExtensions={fileExtensions!} />
          </StyledFlexColumnForMobiles>
        ) : null}
        {requiredDocumentTypes.map((documentType) => {
          return (
            <Box mt="16px" key={documentType.euin}>
              {isClarificationNeeded ? (
                <DocumentTypeToClarify
                  documentType={documentType}
                  documents={documents}
                  fileExtensions={fileExtensions!}
                  handleDocumentRemove={handleDocumentRemove}
                  handleDocumentUpload={handleDocumentUpload}
                  notApplicableDocumentTypes={notApplicableDocumentTypes}
                  handleNonApplicableDocumentTypeToggle={handleNonApplicableDocumentTypeToggle}
                />
              ) : (
                <DocumentType
                  documentType={documentType}
                  documents={documents}
                  fileExtensions={fileExtensions!}
                  handleDocumentRemove={handleDocumentRemove}
                  handleDocumentUpload={handleDocumentUpload}
                  notApplicableDocumentTypes={notApplicableDocumentTypes}
                  handleNonApplicableDocumentTypeToggle={handleNonApplicableDocumentTypeToggle}
                />
              )}
            </Box>
          );
        })}

        {optionalDocumentTypes.length ? (
          <Box mt="16px">
            <Text font="Header/H4">Необязательные к загрузке</Text>
          </Box>
        ) : null}
        {optionalDocumentTypes.map((documentType) => {
          return (
            <Box mt="16px" key={documentType.euin}>
              {isClarificationNeeded ? (
                <DocumentTypeToClarify
                  documentType={documentType}
                  documents={documents}
                  fileExtensions={fileExtensions!}
                  handleDocumentRemove={handleDocumentRemove}
                  handleDocumentUpload={handleDocumentUpload}
                  notApplicableDocumentTypes={notApplicableDocumentTypes}
                  handleNonApplicableDocumentTypeToggle={handleNonApplicableDocumentTypeToggle}
                />
              ) : (
                <DocumentType
                  documentType={documentType}
                  documents={documents}
                  fileExtensions={fileExtensions!}
                  handleDocumentRemove={handleDocumentRemove}
                  handleDocumentUpload={handleDocumentUpload}
                  notApplicableDocumentTypes={notApplicableDocumentTypes}
                  handleNonApplicableDocumentTypeToggle={handleNonApplicableDocumentTypeToggle}
                />
              )}
            </Box>
          );
        })}

        <SalaryCardIssuingAccept
          checked={isSalaryCardIssuingAccepted}
          onChange={() => setIsSalaryCardIssuingAccepted((_) => !_)}
          disabled={profile.data?.candidate.personalDataTransferZP}
        />

        <Box mt="32px">
          <label>
            <Box mt="16px" display="flex" columnGap="8px" cursor={haveDocumentsToSubmitToVtb ? 'pointer' : 'default'}>
              {/* Чекбокс активен если заполнены все обязательные файлы И если добавлялись новые файлы (а равно в статусе SENT_LKK) */}
              <Checkbox
                dimension="m"
                onChange={handleDocumentsAccurancyCheckboxChange}
                disabled={isClarificationNeeded ? false : !(haveDocumentsToSubmitToVtb && isRequiredDocumentsAttached)}
                checked={isUserConfirmsAccuracy}
              />
              <T font="Body/Body 1 Long" as="div">
                Я подтверждаю достоверность сведений
              </T>
            </Box>
          </label>
          <Box mt="16px">
            <Button
              dimension="m"
              appearance="primary"
              onClick={handleClickSubmitButton}
              disabled={!isUserConfirmsAccuracy}
            >
              Отправить
            </Button>
          </Box>
          {showModal && (
            <SalaryCardIssuingAcceptModal
              onCloseModal={() => {
                setShowModal(false);
              }}
              onChange={() => setIsSalaryCardIssuingAccepted((_) => !_)}
              checked={isSalaryCardIssuingAccepted}
              onSubmit={handleSubmit}
            />
          )}
        </Box>
      </>
    </Box>
  );
});
