export type ApiCallStatus = 'initial' | 'awaiting' | 'success' | 'failure';

export type ApiCallStateBase = {
  status: ApiCallStatus;
};

export type ApiCallSuccess<T = unknown> = {
  data: T;
  status: 'success';
  error: null;
};

export type ApiCallFailure<E = Error> = {
  status: 'failure';
  error: E;
  data: null;
};

export type ApiCallInitial = {
  data: null;
  status: 'initial';
  error: null;
};

export type ApiCallAwaiting = {
  data: null;
  status: 'awaiting';
  error: null;
};

export type ApiCallState<T = unknown, E = Error> = ApiCallSuccess<T> | ApiCallFailure<E> | ApiCallInitial | ApiCallAwaiting;

export class ApiCall {
  static success<T = unknown>(data: T): ApiCallSuccess<T> {
    return {
      status: 'success',
      data,
      error: null,
    };
  }

  static failure<E = unknown>(error: E): ApiCallFailure<E> {
    return {
      status: 'failure',
      data: null,
      error,
    };
  }

  static initial(): ApiCallInitial {
    return {
      status: 'initial',
      data: null,
      error: null,
    };
  }

  static awaiting(): ApiCallAwaiting {
    return {
      status: 'awaiting',
      data: null,
      error: null,
    };
  }
}

