export function save(key: string, value: string) {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.error(e);
  }
}

export function load(key: string) {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function remove(key: string) {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error(e);
  }
}
