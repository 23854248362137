import { Box } from '@welcome-ui/box';
import { DocumentWrapper } from './DocumentType';
import styled from 'styled-components';
import { SalaryCardAdvantage, SalaryCardIssuingAcceptCheckbox } from '#components/SalaryCardIssuing';
import { CheckBoxProps, T } from '@admiral-ds/react-ui';

const Wrapper = styled(DocumentWrapper)`
  row-gap: 8px;
  background: #e5f6ff;
`;

export const SalaryCardIssuingAccept = (props: CheckBoxProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'} rowGap={'16px'} mt={'16px'}>
      <T font="Body/Body 2 Short" color="Neutral/Neutral 50">
        Чтобы позаботиться о Вас, мы стараемся оказать сервис уже на этом этапе
      </T>
      <Wrapper>
        <Box w="100%">
          <T font="Header/H5">Получение зарплатной карты</T>
          <SalaryCardAdvantage />
        </Box>
        <SalaryCardIssuingAcceptCheckbox {...props} />
      </Wrapper>
    </Box>
  );
};
