import React, { useContext } from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { Checkbox, T, FileInput, FileItem } from '@admiral-ds/react-ui';
import { IDocumentType, IDocumentUi } from '../models';
import { getMaxPossibleAmountOfFilesToUpload, getNotApplicableText, formatFileType } from '../utils';
import { userContext } from '#user/UserProfileContext';
import { DocumentFileDownload } from './FileDownload';

const Text = styled(T)`
  margin: 0;
`;

export const DocumentWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
  width: 100%;
  padding: 16px 24px 24px;
  box-sizing: border-box;
  background: ${(p) => p.theme.color['Neutral/Neutral 05']};
  border-radius: 24px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const StyledFileInputWrapper = styled(Box)`
  & > div {
    min-width: unset;
  }
`;

export const DocumentType = React.memo(
  (props: {
    documentType: IDocumentType;
    documents: IDocumentUi[];
    fileExtensions: string[];
    handleDocumentUpload: (file: File, vacancyId: string, euin: string) => void;
    handleDocumentRemove: (documentId: string) => void;
    notApplicableDocumentTypes: string[];
    handleNonApplicableDocumentTypeToggle: (euin: string) => void;
  }) => {
    const {
      documentType,
      documents,
      fileExtensions,
      handleDocumentRemove,
      handleDocumentUpload,
      notApplicableDocumentTypes,
      handleNonApplicableDocumentTypeToggle,
    } = props;
    const {
      selectors: { getVacancyId },
    } = useContext(userContext);
    const vacancyId = getVacancyId();
    const maxPossibleAmountOfFilesToUpload = getMaxPossibleAmountOfFilesToUpload(documentType, documents);
    const documentNotApplicable = notApplicableDocumentTypes.includes(documentType.euin);

    return (
      <DocumentWrapper key={documentType.euin}>
        <Box w="100%">
          <Box>
            <Text font="Header/H5">{documentType.title}</Text>
          </Box>
          <Box>
            <Text font="Body/Body 2 Long" color="Neutral/Neutral 90">
              {documentType.description}
            </Text>
          </Box>
          {documentType.mayBeNotApplicable === true ? (
            <Box mb="8px">
              <label>
                <Box mt="18px" display="flex" columnGap="8px" cursor="pointer">
                  <Checkbox
                    dimension="m"
                    onChange={() => {
                      handleNonApplicableDocumentTypeToggle(documentType.euin);
                    }}
                    checked={notApplicableDocumentTypes.includes(documentType.euin)}
                  />
                  <T font="Body/Body 1 Short" as="div">
                    {getNotApplicableText(documentType.euin)}
                  </T>
                </Box>
              </label>
            </Box>
          ) : null}
        </Box>
        <Box w="100%">
          <Box>
            <Text font="Body/Body 2 Short" color="Neutral/Neutral 50">
              Максимальный размер файла – до 5 MB каждый
            </Text>
          </Box>
          <Box mt="8px" mb="8px">
            <StyledFileInputWrapper>
              <FileInput
                dimension="m"
                accept={fileExtensions.map((e) => '.' + e).join(', ')}
                title="Добавьте файлы"
                multiple
                onChange={(e) => {
                  const maxAmountOfFilesToUpload = getMaxPossibleAmountOfFilesToUpload(documentType, documents);
                  Array.from(e?.target?.files || [])
                    // Не даём через drag and drop загрузить больше положенного
                    .slice(0, maxAmountOfFilesToUpload)
                    .forEach((file: File) => {
                      handleDocumentUpload(file, vacancyId!, documentType.euin);
                    });
                  e.target.value = '';
                }}
                disabled={documentNotApplicable || maxPossibleAmountOfFilesToUpload <= 0}
              />
            </StyledFileInputWrapper>
            {documents
              .filter((d) => d.euin === documentType.euin)
              .map((document) => {
                return (
                  <Box mt="-8px" key={document.id} opacity={documentNotApplicable ? 0.5 : 1} position="relative">
                    {document.docSetStatus !== 'SUBMITTED' && <DocumentFileDownload document={document} />}
                    <FileItem
                      style={{
                        color: 'transparent',
                      }}
                      fileType={document.mimeType}
                      formatFileTypeInfo={formatFileType}
                      fileId={document.id}
                      fileName={document.name.split('.')[0]}
                      fileSize={document.size}
                      status={document.uiStatus}
                      onCloseIconClick={
                        document.uiStatus !== 'Loading'
                          ? () => {
                              handleDocumentRemove(document.id);
                            }
                          : undefined
                      }
                      dimension="m"
                    />
                  </Box>
                );
              })}
          </Box>
        </Box>
      </DocumentWrapper>
    );
  },
);
