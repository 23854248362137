import React from 'react';
import styled from 'styled-components';
import { Box } from '@welcome-ui/box';
import { IconPlacement, IconPlacementProps } from '@admiral-ds/react-ui';
import Menu from '@admiral-ds/icons/build/service/MenuOutline.svg?react';

export const MediaWrapper = styled(Box)`
  @media (min-width: 640px) {
    display: none;
  }
  @media (max-width: 639px) {
    display: flex;
  }
`;

export const MainMenuButton = React.memo(
  React.forwardRef(({ ...props }: IconPlacementProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    return (
      <MediaWrapper>
        <IconPlacement ref={ref} {...props}>
          <Menu />
        </IconPlacement>
      </MediaWrapper>
    );
  }),
);
